import React from 'react';
import {POSITION_TYPES} from 'hooks';
import {CBCButton} from 'shared/helpers';
import {useProductContext} from 'contexts';

export const ConvertButton = ({
    fieldsetName,
    handleConvertToPercentage,
    handleConvertToMM,
}: {
    fieldsetName: string;
    handleConvertToPercentage: () => void;
    handleConvertToMM: () => void;
}) => {
    const {
        shelvesPositionType,
    }: {
        shelvesPositionType: {[key: string]: string};
    } = useProductContext();
    const showPercentageButton =
        shelvesPositionType.hasOwnProperty(fieldsetName) &&
        shelvesPositionType[String(fieldsetName)] === POSITION_TYPES.DECIMAL;

    return showPercentageButton ? (
        <CBCButton
            key="convert-percentage-btn"
            tabIndex={-1}
            iconName="Button-Percentage.svg"
            onClick={handleConvertToPercentage}>
            Convert to %
        </CBCButton>
    ) : (
        <CBCButton
            key="convert-mm-btn"
            tabIndex={-1}
            style={{textTransform: 'none'}}
            iconName="Button-Convert-mm.svg"
            onClick={handleConvertToMM}>
            Convert To mm
        </CBCButton>
    );
};
