import {Drawer, DrawerFields} from 'components/customer/Product/entity/Drawer';
import {getFaceHeight} from 'components/customer/Product/Drawer/helpers';
import {cloneDeep} from 'lodash';
import {Fieldset} from 'shared/types';
import {useLazyGetRunnerQuery} from 'components/customer/Product/store/drawerRunnerApi';

// TODO: Using the object as is. Update it to use proper names for the data
interface DrawerDetails {
    cabinet_drawer_bottom: number;
    drawer_gap: number;
    margin_top: number;
    maxRunnerDepth: number;
    totalDrawerHeight: number;
}

interface StructureType {
    name: string;
    fieldsets: Fieldset[];
}

export const getFieldNames = (
    structure: StructureType
): {
    faceHeightFieldName: string;
    drawerStyleFieldName: string;
} => {
    let faceHeightFieldName: string;
    let drawerStyleFieldName: string;

    structure.fieldsets.forEach((fieldset) => {
        if (fieldset.name == 'drawer_runners') {
            fieldset.fields.forEach((field) => {
                if (field.name == 'drawer_runner_specs') {
                    faceHeightFieldName = field.options.drawerHeightFieldName;
                    drawerStyleFieldName = field.options.drawerStyleFieldName;
                }
            });
        }
    });

    return {
        faceHeightFieldName,
        drawerStyleFieldName,
    };
};

export const useGetDrawers = () => {
    const getRunner = useLazyGetRunnerQuery();

    return getDrawers(getRunner);
};

const getDrawers =
    ([getRunner]: ReturnType<typeof useLazyGetRunnerQuery>) =>
    async (
        cabinetId: number,
        values: DrawerFields,
        drawerDetails: DrawerDetails,
        structure: StructureType
    ): Promise<Drawer[]> => {
        const drawers = cloneDeep(values.drawers);
        const {faceHeightFieldName, drawerStyleFieldName} =
            getFieldNames(structure);
        const isNotDrillOnly = values.cabinet_include_hardware == 1;

        if (faceHeightFieldName && drawerStyleFieldName) {
            for (let i = 0; i < drawers.length; i++) {
                drawers[i].drawer_face_height = getFaceHeight(
                    drawers,
                    i,
                    drawerDetails.totalDrawerHeight,
                    drawerDetails.cabinet_drawer_bottom,
                    drawerDetails.margin_top,
                    drawerDetails.drawer_gap,
                    faceHeightFieldName,
                    drawerStyleFieldName
                );

                const {data: runners, isError} = await getRunner(
                    {
                        insertType: drawers[i].drawer_type,
                        faceHeight: drawers[i].drawer_face_height,
                        cabinetId,
                        depth: drawerDetails.maxRunnerDepth,
                        fingerPull:
                            typeof values.fingerPull_styles == 'number'
                                ? values.fingerPull_styles
                                : null,
                    },
                    true
                );

                const filteredRunners = runners.filter(
                    (runner) =>
                        (isNotDrillOnly && !runner.drillOnly) || !isNotDrillOnly
                );

                if (filteredRunners.length == 0 || isError) {
                    throw new Error('No runners available');
                }

                drawers[i].drawer_runner_specs = filteredRunners[0].name;
            }
        }

        return drawers;
    };
