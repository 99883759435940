import {useMemo, useEffect} from 'react';
import {groupBy} from 'lodash';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {
    selectIsNonSupply,
    selectIsNonSupplyCarcase,
} from 'components/customer/Materials/store/materialSlice';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {useProductContext} from 'contexts';
import {setBaseInnerDrawerFaceHeight} from 'components/customer/Preview3D/store/viewerSlice';

interface useProductInclusionProps {
    formValues: any;
    cabinet_details: any;
    drill_only_hinge_details: any;
    productData: any;
    productConfig: any;
}

type ProductInclusionType = {
    title: string;
    hidden?: boolean;
    items: string[];
};

const useProductInclusion = ({
    formValues,
    cabinet_details,
    drill_only_hinge_details,
    productData,
    productConfig,
}: useProductInclusionProps) => {
    const dispatch = useAppDispatch();

    const selectedDrawers = formValues.drawers;
    const drawerAmount = formValues.drawer_amount;
    const selectedTopId = formValues.cabinet_top;
    const selectedHinge = {
        drill_only_hinge_name: formValues.drill_only_hinge_name,
        door_hinge_amount: formValues.door_hinge_amount,
        door_hinge_amount_1: formValues.door_hinge_amount_1,
    };
    const selectedShelves = formValues.shelves;
    const hasAdjustableLegs = formValues.cabinet_adjustable_legs;
    const shelfAmount = formValues.simple_shelves_amount;
    const shelvesPosition = {
        ...(formValues.cabinet_hori_shelves && {
            cabinet_hori_shelves: formValues.cabinet_hori_shelves,
        }),
        ...(formValues.cabinet_vert_shelves && {
            cabinet_vert_shelves: formValues.cabinet_vert_shelves,
        }),
    };
    const shelvesVertical = {
        ...(formValues.upper_shelves && {
            upper_shelves: formValues.upper_shelves,
        }),
        ...(formValues.lower_shelves && {
            lower_shelves: formValues.lower_shelves,
        }),
        ...(formValues.middle_shelves && {
            middle_shelves: formValues.middle_shelves,
        }),
    };
    const hasSimpleShelves = formValues.cabinet_simple_shelves;
    const cabinetWidth = {
        cabinet_width: formValues.cabinet_width,
        cabinet_left_width: formValues.cabinet_left_width,
        cabinet_right_width: formValues.cabinet_right_width,
    };
    const cabinetDepth = {
        cabinet_depth: formValues.cabinet_depth,
        cabinet_left_depth: formValues.cabinet_left_depth,
        cabinet_right_depth: formValues.cabinet_right_depth,
    };

    const isNonSupply = useAppSelector(selectIsNonSupply);
    const isNonSupplyCarcase = useAppSelector(selectIsNonSupplyCarcase);
    const door = useAppSelector(getDoor);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {getProductInfo, setAdjustableLegsQuantity} = useProductContext() as {
        setAdjustableLegsQuantity: (quantity: number) => void;
        getProductInfo: (value: string) => string;
    };
    const productName = getProductInfo('name');

    const supplyHardware = formValues.hasOwnProperty('cabinet_include_hardware')
        ? formValues.cabinet_include_hardware
        : false;

    const drawers =
        selectedDrawers
            ?.map((sd: any) =>
                cabinet_details.drawers?.find(
                    (d: any) => d.id == sd.drawer_type
                )
            )
            .filter((sd: any) => sd) || [];

    let groupedDrawers: any = null;
    let groupedParts: any = null;
    let groupedHardwares: any = null;
    let groupedShelves: any = null;
    let groupedProductHardwares: any = null;
    let groupedProductParts: any = null;

    let partIdKey = 'partId';
    if (!cabinet_details.parts[0][partIdKey]) partIdKey = 'part_id';

    let hardwareKey = 'hardware_id';
    if (
        cabinet_details.hardware[0] &&
        !cabinet_details.hardware[0][hardwareKey]
    )
        hardwareKey = 'hardwareId';

    if (drawers) {
        groupedDrawers = groupBy(drawers, (t) => t.id);
        let tempParts: any = [];

        Object.keys(groupedDrawers).forEach((key) => {
            groupedDrawers[key].forEach((d: any) => {
                if (d.parts) tempParts = [...tempParts, ...d.parts];
            });
        });

        let drawerPartIdKey = 'partId';
        if (tempParts[0] && !tempParts[0][drawerPartIdKey])
            drawerPartIdKey = 'part_id';

        groupedParts = groupBy(tempParts, (tp) => tp[drawerPartIdKey]);

        let tempHardwares: any = [];

        Object.keys(groupedDrawers).forEach((key) => {
            groupedDrawers[key].forEach((d: any) => {
                if (d.hardware)
                    tempHardwares = [...tempHardwares, ...d.hardware];
            });
        });

        groupedHardwares = groupBy(tempHardwares, (tp) => tp[hardwareKey]);
    }

    if (selectedShelves) {
        groupedShelves = groupBy(selectedShelves, (t) => t.shelf_type);
    }

    if (cabinet_details.hardware) {
        groupedProductHardwares = groupBy(
            cabinet_details.hardware,
            (h) => h[hardwareKey]
        );
    }

    if (cabinet_details.parts) {
        groupedProductParts = groupBy(
            cabinet_details.parts.filter((p: any) => p.part_name || p.partName),
            (p) => p[partIdKey]
        );
    }

    const getShelvesVertical = (): string[] => [
        ...(shelvesVertical?.upper_shelves?.length
            ? [
                  `- ${shelvesVertical?.upper_shelves?.length} x ${
                      shelvesVertical?.upper_shelves?.length > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Upper)`,
              ]
            : []),
        ...(shelvesVertical?.middle_shelves?.length
            ? [
                  `- ${shelvesVertical?.middle_shelves?.length} x ${
                      shelvesVertical?.middle_shelves?.length > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Middle)`,
              ]
            : []),
        ...(shelvesVertical?.lower_shelves?.length
            ? [
                  `- ${shelvesVertical?.lower_shelves?.length} x ${
                      shelvesVertical?.lower_shelves?.length > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Lower)`,
              ]
            : []),
    ];

    const getShelvesVerticalComplex = (): string[] => [
        ...(shelvesVertical?.upper_shelves?.length
            ? [
                  `- ${shelvesVertical?.upper_shelves?.length} x ${
                      shelvesVertical?.upper_shelves?.length > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Upper)`,
              ]
            : []),
        ...(shelvesVertical?.middle_shelves?.length
            ? [
                  `- ${shelvesVertical?.middle_shelves?.length} x ${
                      shelvesVertical?.middle_shelves?.length > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Middle)`,
              ]
            : []),
        ...(shelvesVertical?.lower_shelves?.length
            ? [
                  `- ${shelvesVertical?.lower_shelves?.length} x ${
                      shelvesVertical?.lower_shelves?.length > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Lower - Bottom Left)`,
              ]
            : []),
        ...(selectedShelves?.length > 0
            ? [
                  `- ${selectedShelves.length} x ${
                      selectedShelves.length > 1 ? 'Shelves' : 'Shelf'
                  } (Lower - Bottom Right)`,
              ]
            : []),
    ];

    const parseHardwareQuantity = (productHardwares: any) => {
        if (!productHardwares) return null;
        let quantity = 0;

        productHardwares.forEach((p: any) => {
            const conditionalType = p.conditional_type || p.conditionalType;
            const conditionalValue = p.conditional_value || p.conditionalValue;

            if (conditionalType <= 0 && conditionalValue <= 0)
                quantity += parseInt(p.quantity);
            else {
                if (conditionalType == 1) {
                    const cabinetWidthFinal =
                        cabinetWidth && cabinetWidth?.cabinet_width
                            ? cabinetWidth?.cabinet_width
                            : cabinetWidth?.cabinet_left_width >
                              cabinetWidth?.cabinet_right_width
                            ? cabinetWidth?.cabinet_left_width
                            : cabinetWidth?.cabinet_right_width;

                    quantity += cabinetWidthFinal
                        ? (Math.floor(
                              cabinetWidthFinal / parseInt(conditionalValue)
                          ) || 0) * parseInt(p.quantity)
                        : 0;
                }

                if (conditionalType == 2) {
                    const cabinetDepthFinal =
                        cabinetDepth && cabinetDepth?.cabinet_depth
                            ? cabinetDepth?.cabinet_depth
                            : cabinetDepth?.cabinet_left_depth >
                              cabinetDepth?.cabinet_right_depth
                            ? cabinetDepth?.cabinet_left_depth
                            : cabinetDepth?.cabinet_right_depth;
                    quantity += cabinetDepthFinal
                        ? (Math.floor(
                              cabinetDepthFinal / parseInt(conditionalValue)
                          ) || 0) * parseInt(p.quantity)
                        : 0;
                }
            }
        });

        return quantity;
    };

    const hasDrawers = productConfig.find(
        (c: any) => c.name?.toLowerCase() == 'drawers'
    );

    const hasDoors = productConfig.find(
        (c: any) => c.name?.toLowerCase() == 'doors'
    );

    const hasFridgeDoor = productConfig
        .find((c: any) => c.name?.toLowerCase() == 'doors')
        ?.fieldsets?.find((fs: any) => fs.name == 'door_fields_fridge');

    const hasSingleFridgeDoor =
        !hasFridgeDoor && productName?.toLowerCase().indexOf('fridge') > -1;

    const partsDescriptionItems = useMemo(
        (): string[] =>
            Object.keys(groupedProductParts)?.map(
                (key) =>
                    ` - ${
                        groupedProductParts[key]?.length > 1
                            ? `${groupedProductParts[key]?.length} x `
                            : ''
                    }${
                        groupedProductParts[key][0]?.part_name ||
                        groupedProductParts[key][0]?.partName
                    }`
            ) || [],
        [groupedProductParts]
    );

    const cabinetTopsItems = useMemo(
        (): string[] =>
            cabinet_details.cabinet_tops
                ?.filter((top: any) => top.id == selectedTopId)
                .map((top: any) => `- ${top?.name}`),
        [cabinet_details.cabinet_tops, selectedTopId]
    );

    const shelvesItems = useMemo((): string[] => {
        let localShelves: string[] = [];
        const isComplexShelves =
            groupedShelves &&
            typeof shelvesVertical.lower_shelves !== 'undefined';

        if (
            groupedShelves &&
            cabinet_details.shelves?.length > 0 &&
            !isComplexShelves
        )
            localShelves = [
                ...localShelves,
                ...Object.keys(groupedShelves).map(
                    (key) =>
                        `- ${groupedShelves[key].length} x ${
                            groupedShelves[key].length > 1 ? 'Shelves' : 'Shelf'
                        } (${
                            cabinet_details.shelves.find(
                                (s: any) =>
                                    s.id == groupedShelves[key][0]?.shelf_type
                            )?.name
                        })`
                ),
            ];
        else {
            if (Object.keys(shelvesVertical).length > 0) {
                if (isComplexShelves)
                    localShelves = [
                        ...localShelves,
                        ...getShelvesVerticalComplex(),
                    ];
                else localShelves = [...localShelves, ...getShelvesVertical()];
            }
        }

        return localShelves;
    }, [groupedShelves, cabinet_details.shelves]);

    const shelvesItemsDefault = useMemo((): string[] => {
        return cabinet_details.shelves?.length > 0 && shelfAmount > 0
            ? [`- ${shelfAmount} x Shelf (Plain)`]
            : Object.keys(shelvesPosition).length > 0
            ? [
                  `- ${shelvesPosition?.cabinet_hori_shelves} x ${
                      parseInt(shelvesPosition?.cabinet_hori_shelves) > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Horizontal)`,
                  ` - ${shelvesPosition?.cabinet_vert_shelves} x ${
                      parseInt(shelvesPosition?.cabinet_vert_shelves) > 1
                          ? 'Shelves'
                          : 'Shelf'
                  } (Vertical)`,
              ]
            : Object.keys(shelvesVertical).length > 0
            ? getShelvesVertical()
            : [];
    }, [
        shelfAmount,
        cabinet_details.shelves,
        shelvesPosition,
        shelvesVertical,
    ]);

    const hardwareItems = useMemo(
        (): string[] =>
            Object.keys(groupedDrawers)?.map(
                (key) =>
                    `- ${groupedDrawers[key]?.length} x ${
                        !supplyHardware ? 'Drillings for ' : ''
                    } ${groupedDrawers[key][0]?.name}`
            ) || [],
        [groupedDrawers, supplyHardware]
    );

    const drawerPartsItems = useMemo(
        (): string[] =>
            Object.keys(groupedParts)?.map(
                (key) =>
                    `- ${groupedParts[key]?.length} x ${groupedParts[key][0]?.part_name}`
            ) || [],
        [groupedParts]
    );

    const drawerHardwaresItems = useMemo(
        (): string[] =>
            Object.keys(groupedHardwares)?.map(
                (key) =>
                    ` - ${groupedHardwares[key]?.length} x ${groupedHardwares[key][0]?.hardware_name}`
            ) || [],
        [groupedHardwares]
    );

    const has3Doors = productName?.indexOf('L Shape 3 Door') > -1;

    const noHingeStyle =
        cabinet_details.hinges && cabinet_details.hinges[0]
            ? cabinet_details.hinges[0][0] == 21
            : false;

    const hingeMultiplyAmount = has3Doors
        ? 3
        : hasDoors && hasDrawers
        ? productData?.doors?.length - selectedDrawers?.length
        : productData?.doors?.length;

    const hingeAmount =
        productData?.doors?.length > 1
            ? hingeMultiplyAmount *
              (selectedHinge?.door_hinge_amount
                  ? selectedHinge?.door_hinge_amount
                  : 1)
            : (selectedHinge?.door_hinge_amount_1 && !hasSingleFridgeDoor
                  ? selectedHinge?.door_hinge_amount +
                    (hasFridgeDoor ? selectedHinge?.door_hinge_amount_1 : 0)
                  : selectedHinge?.door_hinge_amount) || 2;

    const hingesItems = useMemo(
        (): string[] => [
            `- ${hingeAmount} x ${
                selectedHinge.drill_only_hinge_name ||
                drill_only_hinge_details?.name
            }`,
        ],
        [productData, selectedHinge, drill_only_hinge_details]
    );

    const hingesItemsDefault = useMemo(
        (): string[] => [
            `- ${hingeAmount} x Drillings for ${
                selectedHinge.drill_only_hinge_name ||
                drill_only_hinge_details?.name ||
                'Blum Inserta Hinges'
            }`,
        ],
        [productData, selectedHinge, drill_only_hinge_details]
    );

    const drawerFacesItems = useMemo(
        (): string[] => [
            ...(hasDrawers && (selectedDrawers?.length || drawerAmount)
                ? [
                      `- ${selectedDrawers?.length || drawerAmount} x ${
                          selectedDrawers?.length > 1 ? 'Drawers' : 'Drawer'
                      } (${door?.name})`,
                  ]
                : []),
            ...(hasDoors && productData?.doors?.length
                ? [
                      `- ${
                          has3Doors
                              ? 3
                              : hasDrawers
                              ? productData?.doors?.length -
                                selectedDrawers?.length
                              : hasFridgeDoor
                              ? productData?.doors?.length + 1
                              : productData?.doors?.length
                      } x ${
                          productData?.doors?.length > 1 ? 'Doors' : 'Door'
                      } (${door?.name})`,
                  ]
                : []),
        ],
        [hasDrawers, hasDoors, productData?.doors, selectedDrawers]
    );

    const productHardwareItems = useMemo(
        (): string[] =>
            Object.keys(groupedProductHardwares)
                .map((key) => {
                    const isAdjustableLegs =
                        groupedProductHardwares[key][0]?.hardware_id == 283 ||
                        groupedProductHardwares[key][0]?.hardwareId == 283;
                    if (
                        (!hasAdjustableLegs || hasAdjustableLegs == 0) &&
                        isAdjustableLegs
                    )
                        return null;
                    const harwareQuantity = parseHardwareQuantity(
                        groupedProductHardwares[key]
                    );

                    setAdjustableLegsQuantity(harwareQuantity);

                    return `- ${harwareQuantity} x ${
                        !supplyHardware
                            ? harwareQuantity > 1
                                ? 'Drillings for '
                                : 'Drilling for '
                            : ''
                    }${
                        groupedProductHardwares[key][0]?.hardware_name ||
                        groupedProductHardwares[key][0]?.hardwareName
                    }`;
                })
                .filter((h) => h) || [],
        [groupedProductHardwares, hasAdjustableLegs, supplyHardware]
    );

    useEffect(() => {
        const innerDrawerItems = productHardwareItems.filter(
            (phi) => phi.indexOf('Inner Drawer') > -1
        );
        if (innerDrawerItems?.length > 0)
            dispatch(setBaseInnerDrawerFaceHeight(innerDrawerItems));
    }, [productHardwareItems]);

    const productInclusionList: ProductInclusionType[] = [
        {
            title: 'Parts',
            hidden: isNonSupplyCarcase,
            items: [
                ...partsDescriptionItems,
                ...cabinetTopsItems,
                ...(shelvesItems?.length > 0 && !hasSimpleShelves
                    ? shelvesItems
                    : shelvesItemsDefault),
            ],
        },
        {
            title: 'Hardware',
            hidden: false,
            items: [
                ...hardwareItems,
                ...drawerPartsItems,
                ...drawerHardwaresItems,
                ...(!noHingeStyle && hasDoors
                    ? !supplyHardware &&
                      (selectedHinge.door_hinge_amount ||
                          drill_only_hinge_details?.name)
                        ? hingesItemsDefault
                        : selectedHinge &&
                          (selectedHinge.door_hinge_amount ||
                              drill_only_hinge_details?.name)
                        ? hingesItems
                        : []
                    : []),
            ],
        },
        {
            title:
                (hasDrawers
                    ? hasDoors
                        ? 'Door and Drawer'
                        : 'Drawer'
                    : 'Door') + ' Faces',
            hidden: isNonSupply || (!hasDrawers && !hasDoors),
            items: [...drawerFacesItems],
        },
        {
            title: 'Others',
            hidden: false,
            items: [...productHardwareItems],
        },
    ];

    return {
        productInclusionList,
    };
};

export default useProductInclusion;
