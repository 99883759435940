import {getChangedData} from 'components/customer/Product/helpers/getChangedData';
import {useGetProductDefault} from 'components/customer/Product/helpers/useGetProductDefault';
import {useGetHingeStyle} from 'components/customer/Product/helpers/useGetHingeStyle';
import {useGetDrawerSystem} from 'components/customer/Product/helpers/useGetDrawerSystem';
import {useAppContext} from 'contexts';
import {useMemo} from 'react';
import {useGetHardwareOptionsQuery} from 'components/customer/Product/store/productApi';
import {useGetHingeStylesRoomQuery} from 'components/customer/Room/store/roomApi';

export const useChangedData = ({productId}: {productId: number}) => {
    const {data: defaultsValues} = useGetProductDefault(productId);
    const {data: hingeStyles} = useGetHingeStyle(productId);
    const {data: roomHingeStyles} = useGetHingeStylesRoomQuery();
    const {data: drawerSystem} = useGetDrawerSystem();
    const {data: hardwareOptions} = useGetHardwareOptionsQuery();
    const {userProfile} = useAppContext();

    return useMemo(() => {
        return getChangedData(
            defaultsValues,
            drawerSystem,
            hingeStyles,
            roomHingeStyles,
            hardwareOptions,
            userProfile
        );
    }, [
        productId,
        hingeStyles,
        roomHingeStyles,
        defaultsValues,
        drawerSystem,
        hardwareOptions,
        userProfile,
    ]);
};
