import {useGetProductDefaultQuery} from 'components/customer/Product/store/productApi';

export const useGetProductDefault = (cabinetType: number) => {
    return useGetProductDefaultQuery(
        {
            cabinetType: parseInt(cabinetType),
        },
        {
            skip: typeof cabinetType === 'undefined',
        }
    );
};
