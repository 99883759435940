import {DrawerRunnerRequest} from 'components/customer/Product/store/drawerRunnerApi';
import {useEffect, useRef, useState} from 'react';
import {toNumber} from 'components/customer/Product/Drawer/helpers';
import {
    DrawerFields,
    DrawerKey,
} from 'components/customer/Product/entity/Drawer';
import {useFormikContext} from 'formik';
import {isEqual} from 'lodash';
import {useProductContext} from 'contexts';
import {useSearchParams} from 'react-router-dom';
import Excel from 'shared/Excel';

export const useRunnerRequestParams = (
    runnerIndex: number,
    isDrawerComponent: boolean,
    drawerTypeFieldName: string,
    drawerDepth: string,
    drawerFaceHeightFieldName: string,
    fingerPullFieldName: string
) => {
    const {values} = useFormikContext<DrawerFields>();
    const {getMaterialOptions} =
        useProductContext<{getMaterialOptions: () => object}>();

    const [searchParams] = useSearchParams();

    const [params, setParams] = useState<DrawerRunnerRequest>({});

    const drawerRunnerProps = useRef<DrawerRunnerRequest>({});
    const getDrawerRunnerProps = (): DrawerRunnerRequest => {
        if (isDrawerComponent) {
            return {
                insertType: toNumber(values[drawerTypeFieldName]),
                cabinetId: parseInt(searchParams.get('product')),
                isDrawerComponent,
            };
        }

        const drawer = values.drawers[runnerIndex];
        const materialOptions = getMaterialOptions();
        const depth = Excel.calculate<number>(drawerDepth, {
            ...values,
            ...materialOptions,
        });

        const drawerTypeKey = drawerTypeFieldName as DrawerKey;
        const drawerFaceKey = drawerFaceHeightFieldName as DrawerKey;
        const fingerPull = values[String(fingerPullFieldName)];

        return {
            insertType: toNumber(drawer[drawerTypeKey]),
            faceHeight: toNumber(drawer[drawerFaceKey]),
            cabinetId: parseInt(searchParams.get('product')),
            depth,
            fingerPull: toNumber(fingerPull),
            isDrawerComponent,
        };
    };

    useEffect(() => {
        const runnerProps = getDrawerRunnerProps();

        if (!isEqual(runnerProps, drawerRunnerProps.current)) {
            drawerRunnerProps.current = runnerProps;

            setParams(runnerProps);
        }
    }, [values]);

    return {
        params,
    };
};
