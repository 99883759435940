import {PartialRoom} from 'shared/types/PartialRoom';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {Material, MaterialEdge} from 'components/customer/Materials/entity';

export const getExteriorMaterialSelection = ({
    room,
    cabinetFormFieldDefaults,
    measurement,
    ext,
    extEdge,
    doubleSided,
    horizontalGrain,
    customColour,
}: {
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
    ext: Material;
    extEdge: MaterialEdge;
    doubleSided: boolean;
    horizontalGrain?: boolean;
    customColour?: string;
}): SelectionInterface[] => {
    const rules = [] as SelectionInterface[];

    if (typeof ext == 'undefined' || typeof extEdge == 'undefined') {
        return rules;
    }
    const exteriorId = ext.id;
    const exteriorEdgeId = extEdge.id;
    const isDoubleSided = ext.is_double_sided && doubleSided ? 1 : 0;
    const isGrained = ext.is_grained && horizontalGrain ? 1 : 0;

    if (typeof exteriorId != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_ext_colour !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_ext_colour != exteriorId &&
                exteriorId != parseInt(room.extColour)) ||
            (typeof cabinetFormFieldDefaults.cabinet_ext_colour ==
                'undefined' &&
                exteriorId != parseInt(room.extColour))
        ) {
            rules.push({
                key: 'ext_colour',
                name: 'Exterior Material Colour',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isExteriorColourSet,
                set_key: 'isExteriorColourSet',
                room_data: `${room.extMaterial.name} ${room.extMaterial.finish} - ${room.extMaterial.thickness}${measurement} ${room.extMaterial.brandName} ${room.extMaterial.substrate}`,
                product_data: `${ext.name} ${ext.finish} - ${ext.thickness}${measurement} ${ext.brand?.name} ${ext.substrate}`,
                value: exteriorId,
                image: ext.image,
                apply_to_room: true,
            });
        }
    }

    if (typeof exteriorEdgeId != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_ext_edge_colour !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_ext_edge_colour !=
                    exteriorId &&
                exteriorEdgeId != parseInt(room.extEdgeColour)) ||
            (typeof cabinetFormFieldDefaults.cabinet_ext_edge_colour ==
                'undefined' &&
                exteriorEdgeId != parseInt(room.extEdgeColour))
        ) {
            rules.push({
                key: 'ext_edge_colour',
                name: 'Exterior Edge Colour',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isExteriorEdgeColourSet,
                set_key: 'isExteriorEdgeColourSet',
                room_data: `${room.extEdge.thickness}${measurement} ${room.extEdge.name} ${room.extEdge.brandName} ${room.extEdge.finish}`,
                product_data: `${extEdge.thickness}${measurement} ${extEdge.name} ${extEdge.brand?.name} ${extEdge.finish}`,
                value: exteriorEdgeId,
                image: extEdge.image,
                apply_to_room: true,
            });
        }
    }

    if (
        (typeof cabinetFormFieldDefaults != 'undefined' &&
            typeof cabinetFormFieldDefaults.double_sided_ext != 'undefined' &&
            cabinetFormFieldDefaults.double_sided_ext != isDoubleSided &&
            isDoubleSided != parseInt(room.extDoubleSided)) ||
        (typeof cabinetFormFieldDefaults.double_sided_ext == 'undefined' &&
            isDoubleSided != parseInt(room.extDoubleSided))
    ) {
        rules.push({
            key: 'ext_double_sided',
            name: 'Exterior Double Sided',
            is_saved:
                room.customerOptions &&
                room.customerOptions.isExteriorDoubleSidedSet,
            set_key: 'isExteriorDoubleSidedSet',
            room_data: parseInt(room.extDoubleSided) ? 'Yes' : 'No',
            product_data: isDoubleSided ? 'Yes' : 'No',
            value: isDoubleSided,
            image: ext.image,
            apply_to_room: true,
        });
    }

    if (
        (typeof cabinetFormFieldDefaults != 'undefined' &&
            typeof cabinetFormFieldDefaults.hor_grain_ext != 'undefined' &&
            cabinetFormFieldDefaults.hor_grain_ext != isGrained &&
            isGrained != parseInt(room.extHorGrain)) ||
        (typeof cabinetFormFieldDefaults.hor_grain_ext == 'undefined' &&
            isGrained != parseInt(room.extHorGrain))
    ) {
        rules.push({
            key: 'ext_hor_grain',
            name: 'Exterior Horizontal Grain',
            is_saved:
                room.customerOptions &&
                room.customerOptions.isExteriorHorizontalGrainSet,
            set_key: 'isExteriorHorizontalGrainSet',
            room_data: parseInt(room.extHorGrain) ? 'Yes' : 'No',
            product_data: isGrained ? 'Yes' : 'No',
            value: isGrained,
            image: ext.image,
            apply_to_room: true,
        });
    }

    if (typeof customColour != 'undefined' && ext.is_custom_colour) {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.custom_colour_ext !=
                    'undefined' &&
                cabinetFormFieldDefaults.custom_colour_ext != customColour &&
                customColour != room.extCustomColour) ||
            (typeof cabinetFormFieldDefaults.custom_colour_ext == 'undefined' &&
                customColour != room.extCustomColour)
        ) {
            rules.push({
                key: 'ext_custom_colour',
                name: 'Exterior Custom Colour',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isExteriorCustomColourSet,
                set_key: 'isExteriorCustomColourSet',
                room_data: room.extCustomColour,
                product_data: customColour,
                value: customColour,
                image: extEdge.image,
                apply_to_room: true,
            });
        }
    }

    return rules;
};
