import styled from 'styled-components';

export const DrawerFace = styled.div<{
    $backgroundImage: string;
    $rotate: boolean;
    $size: number;
    $hasTopMargin: boolean;
    $hasBottomMargin: boolean;
}>`
    position: relative;
    overflow: hidden;
    border-color: rgb(var(--primary_colour));
    background: white;

    ${(props) => {
        if (!props.$hasTopMargin) {
            return `
                &:first-child {
                    border-top-right-radius: 6px;
                    border-top-left-radius: 6px;
                }
            `;
        }
    }}

    ${(props) => {
        if (!props.$hasBottomMargin) {
            return `
                &:last-child {
                    border-bottom-right-radius: 6px;
                    border-bottom-left-radius: 6px;
                }
            `;
        }
    }}

    &:before {
        content: '';
        background-image: ${(props) => props.$backgroundImage};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        ${(props) => {
            if (props.$rotate) {
                return `
                left: -4px;
                transform: rotate(90deg);
                height: ${props.$size}px;
                width: ${props.$size}px`;
            }
        }}
    }
`;
