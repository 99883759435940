import {usePositions} from 'hooks';
import React from 'react';
import {GenericFields} from 'components/customer/Product';

import type {Fieldset} from 'shared/types';

type RailPositionType = {
    fieldset: Fieldset;
    fieldName: string;
    selectHandler: () => void;
    isQFP?: boolean;
};

export const RailPositions = ({
    fieldset,
    fieldName,
    selectHandler,
    isQFP = false,
}: RailPositionType) => {
    const {isLattice, positions} = usePositions(
        fieldName,
        fieldset.name,
        fieldset,
        isQFP,
        selectHandler
    );

    if (positions.length > 0 && !isLattice) {
        return (
            <>
                {isQFP ? <></> : <hr />}
                <GenericFields
                    fields={positions}
                    fieldsetName={fieldset.name}
                    fieldsetTitle={fieldset.title}
                    fieldsetLayout={fieldset.options && fieldset.options.layout}
                    isQFP={isQFP}
                />
            </>
        );
    }

    return <></>;
};
