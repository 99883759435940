import {Product as ProductInterface} from 'components/customer/Product/entity';
import {PartialRoom} from 'shared/types/PartialRoom';
import {
    UpperBottomMargin,
    DoorGap,
    LeftGap,
    UpperTopMargin,
    RightGap,
    BaseBottomMargin,
    BaseTopMargin,
} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {ProductType} from 'components/customer/Product/helpers/entity/ProductType';
import {Door} from 'components/customer/Materials/entity';

export const getDoorSelection = ({
    product,
    room,
    exteriorMaterialRules,
    cabinetFormFieldDefaults,
    measurement,
    door,
}: {
    product: ProductInterface;
    room: PartialRoom;
    exteriorMaterialRules: SelectionInterface[];
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
    door: Door;
}): SelectionInterface[] => {
    const rules = [];
    const isExteriorMaterialSaved =
        exteriorMaterialRules &&
        exteriorMaterialRules.every((rule) => rule.is_saved);

    if (!isExteriorMaterialSaved) {
        if (
            typeof product.cabinet_door != 'undefined' &&
            typeof door != 'undefined'
        ) {
            if (
                (typeof cabinetFormFieldDefaults != 'undefined' &&
                    typeof cabinetFormFieldDefaults.cabinet_door !=
                        'undefined' &&
                    cabinetFormFieldDefaults.cabinet_door !=
                        product.cabinet_door &&
                    product.cabinet_door != room.doorStyle) ||
                (typeof cabinetFormFieldDefaults.cabinet_door == 'undefined' &&
                    product.cabinet_door != room.doorStyle)
            ) {
                rules.push({
                    key: 'door_style',
                    name: 'Door Style',
                    is_saved:
                        room.customerOptions &&
                        room.customerOptions.isDoorStyleSet,
                    set_key: 'isDoorStyleSet',
                    room_data: room.door.name,
                    product_data: door?.name,
                    value: product.cabinet_door,
                    image: door?.image?.name,
                    apply_to_room: true,
                });
            }
        }
    }

    if (typeof product.cabinet_door_gap != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_door_gap !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_door_gap !=
                    product.cabinet_door_gap &&
                product.cabinet_door_gap != parseInt(room.doorGap)) ||
            (typeof cabinetFormFieldDefaults.cabinet_door_gap == 'undefined' &&
                product.cabinet_door_gap != parseInt(room.doorGap))
        ) {
            rules.push({
                key: 'door_gap',
                name: 'Door Gap',
                is_saved:
                    room.customerOptions && room.customerOptions.isDoorGapSet,
                set_key: 'isDoorGapSet',
                room_data: `${room.doorGap} ${measurement}`,
                product_data: `${product.cabinet_door_gap} ${measurement}`,
                value: product.cabinet_door_gap,
                image: String(DoorGap),
                apply_to_room: true,
            });
        }
    }

    if (typeof product.cabinet_door_left != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_door_left !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_door_left !=
                    product.cabinet_door_left &&
                product.cabinet_door_left != room.leftGap) ||
            (typeof cabinetFormFieldDefaults.cabinet_door_left == 'undefined' &&
                product.cabinet_door_left != room.leftGap)
        ) {
            rules.push({
                key: 'left_gap',
                name: 'Left Gap',
                is_saved:
                    room.customerOptions && room.customerOptions.isLeftGapSet,
                set_key: 'isLeftGapSet',
                room_data: `${room.leftGap} ${measurement}`,
                product_data: `${product.cabinet_door_left} ${measurement}`,
                value: product.cabinet_door_left,
                apply_to_room: true,
                image: String(LeftGap),
            });
        }
    }

    if (typeof product.cabinet_door_right != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_door_right !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_door_right !=
                    product.cabinet_door_right &&
                product.cabinet_door_right != room.rightGap) ||
            (typeof cabinetFormFieldDefaults.cabinet_door_right ==
                'undefined' &&
                product.cabinet_door_right != room.rightGap)
        ) {
            rules.push({
                key: 'right_gap',
                name: 'Right Gap',
                is_saved:
                    room.customerOptions && room.customerOptions.isRightGapSet,
                set_key: 'isRightGapSet',
                room_data: `${room.rightGap} ${measurement}`,
                product_data: `${product.cabinet_door_right} ${measurement}`,
                value: product.cabinet_door_right,
                apply_to_room: true,
                image: String(RightGap),
            });
        }
    }
    if (product.cabinet_style == ProductType.BASE) {
        if (typeof product.cabinet_door_top != 'undefined') {
            if (
                (typeof cabinetFormFieldDefaults != 'undefined' &&
                    typeof cabinetFormFieldDefaults.cabinet_door_top !=
                        'undefined' &&
                    cabinetFormFieldDefaults.cabinet_door_top !=
                        product.cabinet_door_top &&
                    product.cabinet_door_top != room.baseTopGap) ||
                (typeof cabinetFormFieldDefaults.cabinet_door_top ==
                    'undefined' &&
                    product.cabinet_door_top != room.baseTopGap)
            ) {
                rules.push({
                    key: 'base_top_gap',
                    name: 'Base Top Margin',
                    is_saved:
                        room.customerOptions &&
                        room.customerOptions.isBaseTopMarginSet,
                    set_key: 'isBaseTopMarginSet',
                    room_data: `${room.baseTopGap} ${measurement}`,
                    product_data: `${product.cabinet_door_top} ${measurement}`,
                    value: product.cabinet_door_top,
                    apply_to_room: true,
                    image: String(BaseTopMargin),
                });
            }
        }

        if (typeof product.cabinet_door_bottom != 'undefined') {
            if (
                (typeof cabinetFormFieldDefaults != 'undefined' &&
                    typeof cabinetFormFieldDefaults.cabinet_door_bottom !=
                        'undefined' &&
                    cabinetFormFieldDefaults.cabinet_door_bottom !=
                        product.cabinet_door_bottom &&
                    product.cabinet_door_bottom != room.baseBottomGap) ||
                (typeof cabinetFormFieldDefaults.cabinet_door_bottom ==
                    'undefined' &&
                    product.cabinet_door_bottom != room.baseBottomGap)
            ) {
                rules.push({
                    key: 'base_bottom_gap',
                    name: 'Base Bottom Margin',
                    is_saved:
                        room.customerOptions &&
                        room.customerOptions.isBaseBottomMarginSet,
                    set_key: 'isBaseBottomMarginSet',
                    room_data: `${room.baseBottomGap} ${measurement}`,
                    product_data: `${product.cabinet_door_bottom} ${measurement}`,
                    value: product.cabinet_door_bottom,
                    apply_to_room: true,
                    image: String(BaseBottomMargin),
                });
            }
        }
    }

    if (product.cabinet_style == ProductType.UPPER) {
        if (typeof product.cabinet_door_top != 'undefined') {
            if (
                (typeof cabinetFormFieldDefaults != 'undefined' &&
                    typeof cabinetFormFieldDefaults.cabinet_door_top !=
                        'undefined' &&
                    cabinetFormFieldDefaults.cabinet_door_top !=
                        product.cabinet_door_top &&
                    product.cabinet_door_top != room.upperTopGap) ||
                (typeof cabinetFormFieldDefaults.cabinet_door_top ==
                    'undefined' &&
                    product.cabinet_door_top != room.upperTopGap)
            ) {
                rules.push({
                    key: 'upper_top_gap',
                    name: 'Upper Top Margin',
                    is_saved:
                        room.customerOptions &&
                        room.customerOptions.isUpperTopMarginSet,
                    set_key: 'isUpperTopMarginSet',
                    room_data: `${room.upperTopGap} ${measurement}`,
                    product_data: `${product.cabinet_door_top} ${measurement}`,
                    value: product.cabinet_door_top,
                    apply_to_room: true,
                    image: String(UpperTopMargin),
                });
            }
        }

        if (typeof product.cabinet_door_bottom != 'undefined') {
            if (
                (typeof cabinetFormFieldDefaults != 'undefined' &&
                    typeof cabinetFormFieldDefaults.cabinet_door_bottom !=
                        'undefined' &&
                    cabinetFormFieldDefaults.cabinet_door_bottom !=
                        product.cabinet_door_bottom &&
                    product.cabinet_door_bottom != room.upperBottomGap) ||
                (typeof cabinetFormFieldDefaults.cabinet_door_bottom ==
                    'undefined' &&
                    product.cabinet_door_bottom != room.upperBottomGap)
            ) {
                rules.push({
                    key: 'upper_bottom_gap',
                    name: 'Upper Bottom Margin',
                    is_saved:
                        room.customerOptions &&
                        room.customerOptions.isUpperBottomMarginSet,
                    set_key: 'isUpperBottomMarginSet',
                    room_data: `${room.upperBottomGap} ${measurement}`,
                    product_data: `${product.cabinet_door_bottom} ${measurement}`,
                    value: product.cabinet_door_bottom,
                    apply_to_room: true,
                    image: String(UpperBottomMargin),
                });
            }
        }
    }

    return rules;
};
