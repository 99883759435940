import React, {useMemo, useRef} from 'react';
import {useFormikContext} from 'formik';
import {useProductContext} from 'contexts';
import excel from 'shared/Excel';
import {DrawerFields} from 'components/customer/Product/entity/Drawer';
import {toNumber} from 'components/customer/Product/Drawer/helpers';
import {useInView} from 'react-intersection-observer';
import {DrawerKey} from 'components/customer/Product/entity/Drawer';
import {CSSProperties} from 'styled-components';
import {DrawerFace} from 'components/customer/Product/Drawer/DrawerFace';

interface RunnerPreviewTypes {
    runnerIndex: number;
    totalDrawerHeight: string;
    drawerGapFieldName: string;
    drawerTopMarginFieldName: string;
    drawerBottomMarginFieldName: string;
    drawerFaceHeightFieldName: string;
}

interface RunnerPreviewInterface {
    totalDrawerHeight: number;
    drawerGap: number;
    drawerTopMargin: number;
    drawerBottomMargin: number;
    color?: string;
}

interface DrawerFacesInterface {
    topMargin: number;
    bottomMargin: number;
    darwers: JSX.Element[];
}

export const RunnerPreview = ({
    runnerIndex,
    totalDrawerHeight,
    drawerGapFieldName,
    drawerTopMarginFieldName,
    drawerBottomMarginFieldName,
    drawerFaceHeightFieldName,
}: RunnerPreviewTypes) => {
    const {ref: previewContainer, inView} = useInView({
        threshold: 0,
    });
    const previewElement = useRef<HTMLDivElement>();
    const {values} = useFormikContext<DrawerFields>();
    const {getMaterialOptions} = useProductContext<{
        getMaterialOptions: () => {
            exterior_color: string;
        };
    }>();

    const runnerPreviewParams = useMemo<RunnerPreviewInterface>(() => {
        const materials = getMaterialOptions();
        const totalHeight = excel.calculate<number>(totalDrawerHeight, {
            ...values,
            ...materials,
        });

        const params = {
            totalDrawerHeight: totalHeight,
            drawerGap: toNumber(values[String(drawerGapFieldName)]),
            drawerTopMargin: toNumber(values[String(drawerTopMarginFieldName)]),
            drawerBottomMargin: toNumber(
                values[String(drawerBottomMarginFieldName)]
            ),
            color: '#3F72B9',
        };

        if (materials.exterior_color) {
            if (
                materials.exterior_color.indexOf(
                    'uploads/gocabinet_materials'
                ) == -1
            ) {
                params.color = `/uploads/gocabinet_materials/${materials.exterior_color}`;
            }

            params.color = `${materials.exterior_color}`;
        }

        return params;
    }, [values]);

    const {topMargin, bottomMargin, drawers} =
        useMemo<DrawerFacesInterface>(() => {
            if (inView) {
                const {
                    totalDrawerHeight,
                    drawerGap,
                    drawerTopMargin,
                    drawerBottomMargin,
                    color,
                } = runnerPreviewParams;

                const availableHeight =
                    previewElement.current.getBoundingClientRect().height;
                const ratio = availableHeight / totalDrawerHeight;

                const faceHeightKey = drawerFaceHeightFieldName as DrawerKey;
                const topMargin = Math.floor(ratio * drawerTopMargin);
                const bottomMargin = Math.floor(ratio * drawerBottomMargin);
                const gap = Math.floor(ratio * drawerGap);

                return {
                    topMargin,
                    bottomMargin,
                    drawers: values.drawers.map((drawer, index) => {
                        const faceHeight = drawer[faceHeightKey]
                            ? Math.floor(drawer[faceHeightKey] * ratio)
                            : 0;

                        const style: CSSProperties = {
                            height: `${faceHeight}px`,
                        };

                        let backgroundImage: string;
                        if (index == runnerIndex) {
                            backgroundImage = `url("${color}")`;
                        }

                        if (index >= 0) {
                            style.borderBottomWidth = '2px';
                            style.borderBottomStyle = 'solid';

                            if (topMargin > 0 && index == 0) {
                                style.borderTopWidth = '2px';
                                style.borderTopStyle = 'solid';
                            }
                        }

                        if (
                            index == values.drawers.length - 1 &&
                            bottomMargin <= 0
                        ) {
                            style.borderBottomWidth = '0';
                        }

                        if (gap - 1 > 0 && index >= 1) {
                            style.marginTop = `${gap}px`;
                            style.borderTopWidth = '2px';
                            style.borderTopStyle = 'solid';
                        }

                        const previewWidth =
                            previewElement.current.getBoundingClientRect()
                                .width;
                        const size =
                            previewWidth > faceHeight
                                ? previewWidth
                                : faceHeight;

                        return (
                            <DrawerFace
                                key={index}
                                $backgroundImage={backgroundImage}
                                $rotate={values.hor_grain_ext}
                                $size={size}
                                $hasTopMargin={topMargin > 0}
                                $hasBottomMargin={bottomMargin > 0}
                                className="drawer"
                                style={style}
                            />
                        );
                    }),
                };
            }

            return {
                topMargin: 0,
                bottomMargin: 0,
                drawers: [<React.Fragment key={0} />],
            };
        }, [values.drawers, runnerPreviewParams, inView, values.hor_grain_ext]);

    return (
        <div
            className="cabinet-shape-container"
            style={{
                height: '100%',
                maxHeight: '200px',
                background: '#e5e5e5',
                borderRadius: '8px',
                margin: '0 auto',
            }}
            ref={previewContainer}>
            <div className="leg-container left">
                <div className="leg"></div>
            </div>
            <div className="leg-container right">
                <div className="leg"></div>
            </div>

            <div
                style={{
                    height: '100%',
                    maxHeight: '200px',
                    paddingTop: `${topMargin}px`,
                    paddingBottom: `${bottomMargin}px`,
                }}
                className="cabinet-shape"
                ref={previewElement}>
                {drawers}
            </div>
        </div>
    );
};
