import React, {useCallback, useMemo} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {Drawer} from 'components/customer/Product/entity/Drawer';
import {useFormikContext} from 'formik';
import {Size} from 'shared';
import {useNotificationContext} from 'contexts';
import {validationErrorAppliesToField} from 'shared/validator';

interface DrawerFaceHeightProps {
    runnerIndex: number;
    fieldName: string;
    drawerStyleFieldName: string;
}

export const DrawerFaceHeight = ({
    runnerIndex,
    fieldName,
    drawerStyleFieldName,
}: DrawerFaceHeightProps) => {
    const {values, setFieldValue, setTouched} = useFormikContext<{
        drawers: Drawer[];
    }>();
    const {messages} = useNotificationContext();

    const drawer = values.drawers[Number(runnerIndex)];
    const drawerkey = fieldName as keyof typeof drawer;
    const drawerStyleKey = drawerStyleFieldName as keyof typeof drawer;

    const enabled = useMemo<boolean>(() => {
        return drawer[drawerStyleKey] == 0;
    }, [drawer]);

    const selectHandler = useCallback(async (name: string, value: number) => {
        await setTouched({drawers: true});
        await setFieldValue(`drawers[${runnerIndex}.${name}]`, value);
    }, []);

    const isInvalid = useMemo(() => {
        const errors = messages?.errors;
        if (errors && Array.isArray(errors)) {
            const runnerErrors = errors.filter((error) =>
                validationErrorAppliesToField(
                    error,
                    'drawer_face_height',
                    runnerIndex
                )
            );
            if (runnerErrors.length > 0) {
                return true;
            }
        }

        return false;
    }, [messages]);

    return (
        <Form.Group as={Row}>
            <Form.Label column xs={5}>
                Face Height
                <span>*</span>
            </Form.Label>
            <Col xs={7}>
                <Size
                    name={fieldName}
                    value={drawer[drawerkey]}
                    metric="mm"
                    selectHandler={selectHandler}
                    disabled={!enabled}
                    isInvalid={isInvalid}
                    fieldSetIndex={runnerIndex}
                />
            </Col>
        </Form.Group>
    );
};
