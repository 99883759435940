import {POSITION_TYPES} from 'hooks';
import useSizePercentage from 'hooks/Product/DoorDesign';
import React, {useEffect, useMemo, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';

const parseDisplayValue = (value) => {
    // Check if the value is a percentage and convert it to a decimal
    if (typeof value === 'string' && value.includes('%')) {
        return parseFloat(value) / 100;
    }
    // Otherwise, parse it as a float
    return parseFloat(value);
};

export const SizePercentage = ({
    name,
    fieldsetName,
    selectHandler,
    value,
    metric,
    disabled = false,
    ...otherParams
}) => {
    const [isInvalid, setIsInvalid] = useState('');
    const {
        displayValue,
        decimalValue,
        setDisplayValue,
        setShowPercentage,
        showPercentage,
        setDecimalValue,
        heightReference,
        shelvesPositionType,
    } = useSizePercentage(value, fieldsetName);

    const percentageHandlerFocus = () => {
        if (showPercentage) {
            setDisplayValue(+decimalValue.toFixed(4));
        }
    };

    const percentageHandlerBlur = () => {
        let localValue = parseFloat(displayValue);

        if (localValue < 0) {
            localValue = 0;
            setDecimalValue(0);
        } else {
            if (localValue < 1) {
                if (localValue == value) {
                    setDisplayValue(`${+(decimalValue * 100).toFixed(4)}%`);
                } else {
                    selectHandler(name, localValue);
                    setShowPercentage(true);
                }
            } else {
                if (localValue > heightReference) {
                    selectHandler(name, localValue);
                } else {
                    if (localValue != value) {
                        selectHandler(name, localValue);
                        setShowPercentage(false);
                    }
                }
            }
        }
    };

    const isFieldInvalid = useMemo(() => {
        return (
            otherParams?.isInvalid &&
            parseDisplayValue(displayValue) > heightReference
        );
    }, [otherParams?.isInvalid, displayValue, heightReference]);

    useEffect(() => {
        if (isFieldInvalid) {
            setIsInvalid(`Value cannot be greater than ${heightReference}`);
        } else {
            setIsInvalid('');
        }
    }, [isFieldInvalid, heightReference]);
    return (
        <InputGroup className="cbc-size">
            <Form.Control
                disabled={disabled}
                onFocus={percentageHandlerFocus}
                onBlur={percentageHandlerBlur}
                type="text"
                name={name}
                value={displayValue}
                onChange={(event) => {
                    setDisplayValue(event.target.value);
                }}
                isInvalid={isFieldInvalid}
            />
            {shelvesPositionType[fieldsetName] == POSITION_TYPES.DECIMAL &&
            displayValue >= 1 ? (
                <InputGroup.Append>
                    <InputGroup.Text>{metric}</InputGroup.Text>
                </InputGroup.Append>
            ) : (
                <></>
            )}

            {isInvalid != '' && (
                <Form.Control.Feedback type="invalid">
                    {isInvalid}
                </Form.Control.Feedback>
            )}
        </InputGroup>
    );
};
