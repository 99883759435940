import React, {useCallback, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useProductContext} from 'contexts';
import {SizePercentage} from 'components/customer/Product';

export const AddShelfDialog = ({fieldsetName}: {fieldsetName: string}) => {
    const {
        setNewShelfValue,
        newShelfStore,
    }: {
        setNewShelfValue: (key: string, value: boolean | number) => void;
        newShelfStore: React.MutableRefObject<{
            autoPosition: boolean;
            position: number;
        }>;
    } = useProductContext();
    const [autoPosition, setAutoPosition] = useState(
        newShelfStore.current.autoPosition
    );
    const [position, setPosition] = useState(newShelfStore.current.position);

    const toggleAutoPosition = useCallback(
        (value: boolean) => () => {
            setAutoPosition(value);
            setNewShelfValue('autoPosition', value);
        },
        []
    );

    const handlePositionUpdate = useCallback((name: string, value: number) => {
        setPosition(value);
        setNewShelfValue('position', value);
    }, []);

    return (
        <>
            <p>How would you like to position the shelf?</p>

            <Row>
                <Col>
                    <div className="cbc-radio round">
                        <Form.Check
                            type="radio"
                            id="auto_position"
                            name="positionType"
                            label="Auto position"
                            value="auto_position"
                            checked={autoPosition}
                            onChange={toggleAutoPosition(true)}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="cbc-radio round">
                        <Form.Check
                            type="radio"
                            id="set_position"
                            name="positionType"
                            label="Set Position"
                            value="set_position"
                            checked={!autoPosition}
                            onChange={toggleAutoPosition(false)}
                        />
                    </div>

                    <SizePercentage
                        name="new_position"
                        disabled={autoPosition}
                        selectHandler={handlePositionUpdate}
                        value={position}
                        metric="mm"
                        fieldsetName={fieldsetName}
                    />
                </Col>
            </Row>
        </>
    );
};
