import React, {memo, FC} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useProductContext} from 'contexts';

import DynamicImage from 'components/customer/Product/Description/DynamicImage';

interface ProductDescriptionProps {
    manufacturerDescription: any;
    inSizesTab: boolean;
    toggleManufacturerDescription: any;
    productData: any;
    formStructure: any;
    addToFavourite: any;
    isFavourite: any;
    favouriteButtonDisabled: any;
    formValues: any;
}

const ProductDescription: FC<ProductDescriptionProps> = ({
    manufacturerDescription,
    inSizesTab,
    toggleManufacturerDescription,
    productData,
    formStructure,
    addToFavourite,
    isFavourite,
    favouriteButtonDisabled,
    formValues,
}) => {
    const {getProductInfo} = useProductContext() as any;

    const colSpan = inSizesTab ? 4 : 2;

    const handleClick = () => {
        toggleManufacturerDescription(
            manufacturerDescription.length > 90 ? 1 : 0
        );
    };

    const manufacturerDescriptionClassName = inSizesTab
        ? 'manufacturer-description-sizes-tab'
        : 'manufacturer-description';

    const dynamicDescription = (
        <span
            onClick={handleClick}
            className={
                manufacturerDescription ? manufacturerDescriptionClassName : ''
            }>
            {' '}
            {manufacturerDescription}{' '}
        </span>
    );

    const manufacturerDescriptionRow = manufacturerDescription && (
        <div className="manufacturer-description-column">
            {dynamicDescription}
        </div>
    );

    const dynamicImageRow = getProductInfo('image') &&
        getProductInfo('name') && (
            <Row>
                <DynamicImage
                    productData={productData}
                    productConfig={JSON.parse(JSON.stringify(formStructure))}
                    inSizesTab={inSizesTab}
                    addToFavourite={addToFavourite}
                    isFavourite={isFavourite}
                    favouriteButtonDisabled={favouriteButtonDisabled}
                    formValues={formValues}
                    manufacturerDescription={manufacturerDescriptionRow}
                />
            </Row>
        );

    return (
        <Col md={{offset: 0, span: colSpan}} xs={{offset: 0, span: 12}}>
            {dynamicImageRow}
        </Col>
    );
};

export default memo(ProductDescription);
