// @flow
import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useProductContext} from 'contexts';

type BreadcrumbsType = {
    isHardware?: boolean,
};

export const Breadcrumbs = ({
    isHardware = false,
}: BreadcrumbsType): React$Element<any> => {
    const {jobId, roomId} = useParams();
    const {breadcrumbs, popBreadCrumb} = useProductContext();

    return (
        <ul>
            <li>
                <Link
                    onClick={() => popBreadCrumb(-1)}
                    to={
                        isHardware
                            ? `/v2/job/${jobId}/hardware`
                            : `/v2/job/${jobId}/room/${roomId}/product`
                    }>
                    All Categories
                </Link>
            </li>
            {breadcrumbs.map((item, index) => {
                if (item.hasOwnProperty('link'))
                    return (
                        <li key={index}>
                            <Link
                                onClick={() => popBreadCrumb(index)}
                                to={item.link}>
                                {item.key}
                            </Link>
                        </li>
                    );
                else
                    return (
                        <li key={index}>
                            <a>{item.key}</a>
                        </li>
                    );
            })}
        </ul>
    );
};
