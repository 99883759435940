import React, {useCallback, useEffect} from 'react';
import {POSITION_TYPES, useShelvesInit} from 'hooks';
import {useProductContext} from 'contexts';
import {GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import useSizePercentage from 'hooks/Product/DoorDesign';
import {Field} from 'shared/types';
import {ConvertButton} from 'components/customer/Product/Specs/ConvertButton';

interface VerticalPartitionProps {
    fields: Field[];
    fieldsetName: string;
    fieldsetLayout: string;
    fieldsetTitle: string;
    preview?: boolean;
}

interface VerticalPartitionContext {
    setPositionType: (key: string, value: string) => void;
    setHeightReference: (key: string, value: string | number) => void;
    shelvesHeightUpdated: unknown;
    shelvesPositionType: {[key: string]: string};
}

export const VerticalPartition = ({
    fields,
    fieldsetName,
    fieldsetLayout,
    fieldsetTitle,
    preview = false,
}: VerticalPartitionProps) => {
    const {
        setHeightReference,
        shelvesHeightUpdated,
        setPositionType,
    }: VerticalPartitionContext = useProductContext();
    const {values, setFieldValue, touched} = useFormikContext<{
        [key: string]: number;
    }>();
    const {decimalValue, showPercentage} = useSizePercentage(
        values.cabinet_partition_width,
        fieldsetName
    );

    useEffect(() => {
        setHeightReference(fieldsetName, values.cabinet_width);
        void setFieldValue(fieldsetName, values.cabinet_partition_width);
    }, [
        values.cabinet_width,
        values.cabinet_partition_width,
        shelvesHeightUpdated,
    ]);

    useEffect(() => {
        if (touched?.cabinet_width && showPercentage) {
            void setFieldValue('cabinet_partition_width', decimalValue);
        }
    }, [touched.cabinet_width]);

    useShelvesInit(fieldsetName);

    const handleConvertToPercentage = useCallback(() => {
        setPositionType(fieldsetName, POSITION_TYPES.PERCENTAGE);
        const value =
            values[String(fieldsetName)] > 1
                ? values[String(fieldsetName)] / values.cabinet_width
                : values[String(fieldsetName)];
        void setFieldValue(fieldsetName, value);
        void setFieldValue('cabinet_partition_width', value);
    }, [values]);

    const handleConvertToMM = useCallback(() => {
        setPositionType(fieldsetName, POSITION_TYPES.DECIMAL);
        const value =
            values[String(fieldsetName)] < 1
                ? values[String(fieldsetName)] * values.cabinet_width
                : values[String(fieldsetName)];
        void setFieldValue(fieldsetName, value);
        void setFieldValue('cabinet_partition_width', value);
    }, [values]);

    return (
        <GenericFields
            fields={fields}
            fieldsetName={fieldsetName}
            fieldsetLayout={fieldsetLayout}
            fieldsetTitle={fieldsetTitle}
            preview={preview}
            formLabelSpanOverride={4}
            colSpanFull
            buttonSlot={
                <div className="buttons-container-shelf">
                    <ConvertButton
                        fieldsetName={fieldsetName}
                        handleConvertToMM={handleConvertToMM}
                        handleConvertToPercentage={handleConvertToPercentage}
                    />
                </div>
            }
        />
    );
};
