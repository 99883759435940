import {AppApi} from 'store/customer/api';
import {DrawerRunner} from 'components/customer/Product/entity/DrawerRunner';
import {DrawerSystem, DrawerSystemApi} from 'shared/types/DrawerSystem';
import {
    DrawerRunnerState,
    setRunnersLoadingState,
} from 'components/customer/Product/store/drawerRunnerSlice';
import {cloneDeep} from 'lodash';

export interface DrawerRunnerRequest {
    insertType?: number;
    faceHeight?: number;
    cabinetId?: number;
    depth?: number;
    fingerPull?: number;
    isDrawerComponent?: boolean;
}

const drillOnlyString = '(drill only)';

const transformDrawerRunnerName = (drawerRunner: DrawerRunner) => ({
    ...drawerRunner,
    ...{
        id:
            `${drawerRunner.runnerHeight}x${drawerRunner.runnerDepth} : ${drawerRunner.runnerDesc}` +
            (drawerRunner.drillOnly ? ' ' + drillOnlyString : ''),
        name:
            `${drawerRunner.runnerHeight}x${drawerRunner.runnerDepth} : ${drawerRunner.runnerDesc}` +
            (drawerRunner.drillOnly ? ' ' + drillOnlyString : ''),
    },
});

const transformDrillOnly = (drawerRunner: DrawerRunner) => ({
    ...drawerRunner,
    ...{
        drillOnly: drawerRunner.drillOnly == 1,
    },
});

const transformRunnerImage = (drawerRunner: DrawerRunner) => ({
    ...drawerRunner,
    ...{
        image: `/uploads/images/Drawer Runner/${drawerRunner.imageName}`,
    },
});

export const DrawerRunnerApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getRunner: builder.query<DrawerRunner[], DrawerRunnerRequest>({
            query: ({
                insertType,
                faceHeight,
                cabinetId,
                depth,
                fingerPull,
                isDrawerComponent,
            }) => {
                if (insertType == null || faceHeight <= -1) {
                    throw new Error(
                        'Please provide insert type and correct face height'
                    );
                }

                if (!isDrawerComponent && faceHeight <= 0) {
                    throw new Error('Invalid face height');
                }

                let url = `/hardware/drawerRunners/cabientId/${cabinetId}/insertType/${insertType}`;

                if (faceHeight) {
                    url += `/faceHeight/${faceHeight}`;
                }

                if (depth) {
                    url += `/depth/${depth}`;
                }

                if (fingerPull && fingerPull != null) {
                    url += `/fingerPull/${fingerPull}`;
                }

                return url;
            },
            transformResponse: (response: {data: DrawerRunner[]}) =>
                response.data
                    .map(transformDrawerRunnerName)
                    .map(transformDrillOnly)
                    .map(transformRunnerImage),
            providesTags: (result, error, params) => [
                {type: 'DrawerRunner', params},
            ],
            onQueryStarted: async (
                {},
                {dispatch, getState, queryFulfilled, requestId}
            ) => {
                const updateRunnerState = (id: string, status: boolean) => {
                    const {
                        drawerRunner: {runnersLoadingState},
                    } = getState() as unknown as {
                        drawerRunner: DrawerRunnerState;
                    };
                    dispatch(
                        setRunnersLoadingState({
                            ...cloneDeep(runnersLoadingState),
                            [id]: status,
                        })
                    );
                };
                try {
                    updateRunnerState(requestId, true);
                    await queryFulfilled;
                } catch (e) {
                    throw e;
                } finally {
                    updateRunnerState(requestId, false);
                }
            },
        }),
        getDrawerSystem: builder.query<DrawerSystem[], void>({
            query: () => '/rooms/drawer-systems',
            transformResponse: (response: {data: DrawerSystemApi[]}) => {
                return response.data.map((drawerSystem) => ({
                    ...drawerSystem,
                    hiddenFromRoom: drawerSystem.hiddenFromRoom != 0,
                }));
            },
            providesTags: [{type: 'DrawerSystem'}],
        }),
    }),
});

export const {
    useLazyGetRunnerQuery,
    useGetRunnerQuery,
    useGetDrawerSystemQuery,
} = DrawerRunnerApi;
