import {PartialRoom} from 'shared/types/PartialRoom';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {Material, MaterialEdge} from 'components/customer/Materials/entity';

export const getCarcaseMaterialSelection = ({
    room,
    cabinetFormFieldDefaults,
    measurement,
    carc,
    carcEdge,
    horizontalGrain,
    customColour,
}: {
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
    carc: Material;
    carcEdge: MaterialEdge;
    horizontalGrain?: boolean;
    customColour?: string;
}): SelectionInterface[] => {
    const rules = [] as SelectionInterface[];

    if (typeof carc == 'undefined' || typeof carcEdge == 'undefined') {
        return rules;
    }
    const carcaseId = carc.id;
    const carcaseEdgeId = carcEdge.id;
    const isDoubleSided = carc.is_double_sided ? 1 : 0;
    const isGrained = carc.is_grained && horizontalGrain ? 1 : 0;

    if (typeof carcaseId != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_carc_colour !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_carc_colour != carcaseId &&
                carcaseId != parseInt(room.carcColour)) ||
            (typeof cabinetFormFieldDefaults.cabinet_carc_colour ==
                'undefined' &&
                carcaseId != parseInt(room.carcColour))
        ) {
            rules.push({
                key: 'carc_colour',
                name: 'Carcase Material Colour',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isCarcaseColourSet,
                set_key: 'isCarcaseColourSet',
                room_data: `${room.carcMaterial.name} ${room.carcMaterial.finish} - ${room.carcMaterial.thickness}${measurement} ${room.carcMaterial.brandName} ${room.carcMaterial.substrate}`,
                product_data: `${carc.name} ${carc.finish} - ${carc.thickness}${measurement} ${carc.brand?.name} ${carc.substrate}`,
                value: carcaseId,
                image: carc.image,
                apply_to_room: true,
            });
        }
    }

    if (typeof carcaseEdgeId != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_carc_edge_colour !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_carc_edge_colour !=
                    carcaseEdgeId &&
                carcaseEdgeId != parseInt(room.carcEdgeColour)) ||
            (typeof cabinetFormFieldDefaults.cabinet_carc_edge_colour ==
                'undefined' &&
                carcaseEdgeId != parseInt(room.carcEdgeColour))
        ) {
            rules.push({
                key: 'carc_edge_colour',
                name: 'Carcase Edge Colour',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isCarcaseEdgeColourSet,
                set_key: 'isCarcaseEdgeColourSet',
                room_data: `${room.carcEdge.thickness}${measurement} ${room.carcEdge.name} ${room.carcEdge.brandName} ${room.carcEdge.finish}`,
                product_data: `${carcEdge.thickness}${measurement} ${carcEdge.name} ${carcEdge.brand?.name} ${carcEdge.finish}`,
                value: carcaseEdgeId,
                image: carcEdge.image,
                apply_to_room: true,
            });
        }
    }

    if (
        typeof isDoubleSided != 'undefined' &&
        isDoubleSided != parseInt(room.carcDoubleSided)
    ) {
        rules.push({
            key: 'carc_double_sided',
            name: 'Carcase Double Sided',
            is_saved:
                room.customerOptions &&
                room.customerOptions.isCarcaseDoubleSidedSet,
            set_key: 'isCarcaseDoubleSidedSet',
            room_data: parseInt(room.carcDoubleSided) ? 'Yes' : 'No',
            product_data: isDoubleSided ? 'Yes' : 'No',
            value: isDoubleSided,
            image: carc.image,
            apply_to_room: true,
        });
    }

    if (
        typeof isGrained != 'undefined' &&
        isGrained != parseInt(room.carcHorGrain)
    ) {
        rules.push({
            key: 'carc_hor_grain',
            name: 'Carcase Horizontal Grain',
            is_saved:
                room.customerOptions &&
                room.customerOptions.isCarcaseHorizontalGrainSet,
            set_key: 'isCarcaseHorizontalGrainSet',
            room_data: parseInt(room.carcHorGrain) ? 'Yes' : 'No',
            product_data: isGrained ? 'Yes' : 'No',
            value: isGrained,
            image: carc.image,
            apply_to_room: true,
        });
    }

    if (typeof customColour != 'undefined' && carc.is_custom_colour) {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.custom_colour_carc !=
                    'undefined' &&
                cabinetFormFieldDefaults.custom_colour_carc != customColour &&
                customColour != room.carcCustomColour) ||
            (typeof cabinetFormFieldDefaults.custom_colour_carc ==
                'undefined' &&
                customColour != room.carcCustomColour)
        ) {
            rules.push({
                key: 'carc_custom_colour',
                name: 'Carcase Custom Colour',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isCarcaseCustomColourSet,
                set_key: 'isCarcaseCustomColourSet',
                room_data: room.carcCustomColour,
                product_data: customColour,
                value: customColour,
                image: carcEdge.image,
                apply_to_room: true,
            });
        }
    }

    return rules;
};
