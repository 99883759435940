import {useLazyGetProductDefaultQuery} from 'components/customer/Product/store/productApi';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';

export const useGetProductDefaultQuery = () => {
    const {notify} = useNotificationContext();
    const [getProductDefaultValues, status] = useLazyGetProductDefaultQuery();

    const fetchProductDefaultValues = async (cabinetType: number) => {
        try {
            const {
                data: defaultValues,
                isError,
                error,
            } = await getProductDefaultValues(
                {
                    cabinetType,
                },
                true
            );

            if (isError) {
                const errMsg =
                    'error' in error ? error.error : JSON.stringify(error.data);
                throw new Error(errMsg);
            }

            return defaultValues;
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch product default values',
            });
        }
    };

    return {
        fetchProductDefaultValues,
        ...status,
    };
};
