import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {DrawerFaceHeight} from 'components/customer/Product/Drawer/DrawerFaceHeight';
import {DrawerStyle} from 'components/customer/Product/Drawer/DrawerStyle';
import {DrawerType} from 'components/customer/Product/Drawer/DrawerType';
import {DrawerRunner} from 'components/customer/Product/Drawer/DrawerRunner';
import {RunnerPreview} from 'components/customer/Product/Drawer/RunnerPreview';
import {DrawerParameters} from 'components/customer/Product/entity/DrawerParameters';
import {DrawerThumbnail} from './DrawerThumbnail';

interface DrawerType {
    runnerIndex: number;
    showLine: boolean;
    drawerParams: DrawerParameters;
}

export const Drawer = ({
    runnerIndex,
    showLine = true,
    drawerParams,
}: DrawerType): JSX.Element => {
    return (
        <>
            <Row className="drawer-runner">
                <Col md={7} xs={12}>
                    <strong className="compound_title">
                        Drawer #{runnerIndex + 1}
                    </strong>

                    <DrawerType
                        runnerIndex={runnerIndex}
                        fieldName={drawerParams.drawerTypeFieldName}
                    />
                    <DrawerStyle
                        runnerIndex={runnerIndex}
                        fieldName={drawerParams.drawerStyleFieldName}
                    />
                    <DrawerFaceHeight
                        runnerIndex={runnerIndex}
                        fieldName={drawerParams.drawerFaceHeightFieldName}
                        drawerStyleFieldName={drawerParams.drawerStyleFieldName}
                    />
                    <DrawerRunner
                        runnerIndex={runnerIndex}
                        fieldName={drawerParams.drawerRunnerFieldName}
                        drawerTypeFieldName={drawerParams.drawerTypeFieldName}
                        drawerFaceHeightFieldName={
                            drawerParams.drawerFaceHeightFieldName
                        }
                        fingerPullFieldName={drawerParams.fingerPullFieldName}
                        drawerDepth={drawerParams.maxRunnerDepth}
                    />
                </Col>
                <Col md={2} xs={6}>
                    <RunnerPreview
                        runnerIndex={runnerIndex}
                        totalDrawerHeight={drawerParams.totalDrawerHeight}
                        drawerGapFieldName={drawerParams.drawerGapFieldName}
                        drawerTopMarginFieldName={
                            drawerParams.drawerTopMarginFieldName
                        }
                        drawerBottomMarginFieldName={
                            drawerParams.drawerBottomMarginFieldName
                        }
                        drawerFaceHeightFieldName={
                            drawerParams.drawerFaceHeightFieldName
                        }
                    />
                </Col>
                <Col md={3} xs={6}>
                    <DrawerThumbnail
                        runnerIndex={runnerIndex}
                        drawerTypeFieldName={drawerParams.drawerTypeFieldName}
                        drawerFaceHeightFieldName={
                            drawerParams.drawerFaceHeightFieldName
                        }
                        fingerPullFieldName={drawerParams.fingerPullFieldName}
                        drawerDepth={drawerParams.maxRunnerDepth}
                    />
                </Col>
            </Row>
            {showLine ? <hr /> : <></>}
        </>
    );
};
