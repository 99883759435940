import {useDoorCanvas} from 'hooks';
import {Row, Col, Button} from 'react-bootstrap';
import React, {useState, useCallback, Suspense, lazy} from 'react';
import {useProductContext} from 'contexts';
import {Boolean} from 'shared';
import styled from 'styled-components';
import {useAppSelector} from 'store/customer';
import {getHasWebGLError} from 'components/customer/Preview3D/store/viewerSlice';

type DoorPreviewType = {
    appliedPanel: boolean;
    hingeDirection: string;
    isQFP: boolean;
    has3DPreview: boolean;
};

const Preview3DComponent = lazy(
    () => import('components/customer/Preview3D/components/Preview3DComponent')
);

export const DoorPreview = ({
    appliedPanel = false,
    hingeDirection,
    isQFP,
    has3DPreview,
}: DoorPreviewType) => {
    const {position, canvasContainerId} = useDoorCanvas({
        appliedPanel,
        hingeDirection,
        isQFP,
    });
    const hasWebGLError = useAppSelector(getHasWebGLError);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {doorPreviewOpacity, setDoorPreviewOpacity} = useProductContext() as {
        doorPreviewOpacity: number;
        setDoorPreviewOpacity: (opacity: number) => void;
    };
    const [show3DPreview, setShow3DPreview] = useState(true);

    const opacityCheckboxHandler = useCallback(
        (name: string, value: boolean) => {
            setDoorPreviewOpacity(value ? 1 : 0.5);
        },
        []
    );

    const toggle3DPreviewHandler = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setShow3DPreview(!show3DPreview);
            event.currentTarget.blur();
        },
        [show3DPreview, position, canvasContainerId]
    );

    return (
        <div style={position}>
            <RowStyled>
                <Col>
                    {!show3DPreview || !has3DPreview ? (
                        <div className="door-preview-opacity-toggle">
                            <span className="label">
                                Toggle door preview opacity
                            </span>
                            <Boolean
                                type="checkbox"
                                name="opacity"
                                value={doorPreviewOpacity == 1}
                                setFieldValue={opacityCheckboxHandler}
                            />
                        </div>
                    ) : null}
                </Col>
                <Col xs="auto">
                    {has3DPreview && !hasWebGLError ? (
                        <SwitchTo3DButton
                            variant="primary"
                            onClick={toggle3DPreviewHandler}>
                            Switch to{' '}
                            {show3DPreview ? 'Margins View' : '3D View'}
                        </SwitchTo3DButton>
                    ) : null}
                </Col>
            </RowStyled>

            {has3DPreview && !hasWebGLError ? (
                <ToggleDiv $show={show3DPreview && has3DPreview ? true : false}>
                    <Suspense fallback={null}>
                        <Preview3DComponent previewLocation={'DOORS'} />
                    </Suspense>
                </ToggleDiv>
            ) : null}

            <ToggleDiv
                $show={!(show3DPreview && has3DPreview) || hasWebGLError}
                $opacityToggle>
                <div className="panelContainer" id={canvasContainerId} />
                <div>
                    <p className="door-preview-note">
                        <strong>Note: </strong> Door preview is for
                        demonstrational purposes only. External edges of door
                        may be further from or closer to the cabinet carcase
                        than what is shown in the preview.
                    </p>
                </div>
            </ToggleDiv>
        </div>
    );
};

const SwitchTo3DButton = styled(Button)`
    font-size: 0.75rem;
    font-weight: 500;
    background: rgb(var(--secondary_colour)) !important;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
        background-color: rgb(var(--primary_colour)) !important;
    }
    &:focus,
    &:active,
    &:focus-visible {
        background-color: rgb(var(--primary_colour)) !important;
        box-shadow: none;
    }
`;

const RowStyled = styled(Row)`
    align-items: center;
    margin-bottom: 5px;
`;

const ToggleDiv = styled.div<{$show?: boolean; $opacityToggle?: boolean}>`
    ${({$show, $opacityToggle}) =>
        !$show
            ? $opacityToggle
                ? 'opacity: 0; height: 0; overflow: hidden;'
                : 'display: none;'
            : ''}
`;
