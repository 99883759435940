import {GenericObject} from 'components/customer/Product/entity';
import excel from 'shared/Excel';

interface DraerRunnerInfoRef extends GenericObject {
    maxRunnerDepth: string | number;
}

interface DrawerRunnerDataInterface extends GenericObject {
    drawer_gap: string | number | string[];
    margin_top: string | number | string[];
    material?: string;
    material_value?: string | number | string[];
    totalDrawerHeight?: number;
    maxRunnerDepth?: number;
}

interface DrawerRunnerDataParams {
    values: GenericObject;
    drawerRunnerInfoReference: {
        current: DraerRunnerInfoRef;
    };
    cabinetTotalDrawerHeight: string;
}

export const getDrawerRunnerDetails = ({
    values,
    drawerRunnerInfoReference,
    cabinetTotalDrawerHeight,
}: DrawerRunnerDataParams) => {
    const drawerRunnerData: DrawerRunnerDataInterface = {
        drawer_gap: 0,
        margin_top: 0,
    };

    if (drawerRunnerInfoReference.current) {
        Object.values(drawerRunnerInfoReference.current).forEach(
            (field, index) => {
                if (
                    typeof field === 'string' &&
                    field.indexOf('cabinet') >= 0 &&
                    values.hasOwnProperty(field)
                ) {
                    const key = Object.keys(drawerRunnerInfoReference.current)[
                        Number(index)
                    ];

                    if (key === 'material') {
                        drawerRunnerData[String(key)] = field;

                        // Adding values to update when color is changed
                        drawerRunnerData[`${key}_value`] =
                            values[String(field)];
                    } else if (
                        key == 'totalDrawerHeight' &&
                        cabinetTotalDrawerHeight
                    ) {
                        drawerRunnerData[String(key)] = excel.calculate(
                            cabinetTotalDrawerHeight,
                            values
                        );
                    } else {
                        drawerRunnerData[String(key)] = values[String(field)];
                    }
                }
            }
        );

        if (
            !drawerRunnerData.hasOwnProperty('maxRunnerDepth') &&
            drawerRunnerInfoReference.current.hasOwnProperty('maxRunnerDepth')
        ) {
            drawerRunnerData.maxRunnerDepth = parseFloat(
                excel.calculate(
                    drawerRunnerInfoReference.current.maxRunnerDepth,
                    values
                )
            );
        }

        drawerRunnerData.drawer_gap = values.cabinet_drawer_gap;
        drawerRunnerData.margin_top = values.cabinet_drawer_top;

        if (values.cabinet_drawer_bottom) {
            drawerRunnerData.cabinet_drawer_bottom =
                values.cabinet_drawer_bottom;
        }
    }

    return drawerRunnerData;
};
