/* eslint-disable react/jsx-no-bind */
import React, {useCallback} from 'react';
import {POSITION_TYPES, useShelves, useShelvesInit} from 'hooks';
import {Button, Col, Row} from 'react-bootstrap';
import {OverlayTrigger} from 'shared';
import {CBCButton, getFieldValue, Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useProductContext} from 'contexts';
import {GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import {AddShelfDialog} from 'components/customer/Product/Specs/AddShelfDialog';
import {Field, Fieldset} from 'shared/types';
import {ConvertButton} from 'components/customer/Product/Specs/ConvertButton';

export const Shelves = ({
    fields,
    fieldset,
}: {
    fields: Field;
    fieldset: Fieldset;
}) => {
    const {
        addShelfDialog,
        deleteShelfDialog,
        copyShelfDialog,
        autoPosition,
        dialog,
        shelfFieldsets,
        fieldsetName,
        isLimitReached,
    } = useShelves(fields, fieldset);
    const {
        setPositionType,
    }: {
        setPositionType: (name: string, value: string) => void;
    } = useProductContext();
    const {values, setFieldValue} = useFormikContext<{
        [key: string]: unknown[];
    }>();
    const isSmallDevice = isDeviceSmall();

    useShelvesInit(fieldsetName);

    const togglePositionType = useCallback(
        (name: string, value: string) => () => {
            setPositionType(name, value);
        },
        []
    );

    return (
        <>
            {shelfFieldsets.length
                ? shelfFieldsets.map((shelfFieldset: Fieldset, index) => {
                      const indexedValue = values[String(fieldsetName)]
                          ? values[String(fieldsetName)][Number(index)]
                          : {};
                      const {id = index}: {id?: number} = indexedValue || {};
                      return (
                          <React.Fragment key={id}>
                              <Row>
                                  <Col className="shelf" md={4}>
                                      <section
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                          }}>
                                          <strong>
                                              {getFieldValue(fieldset.title, {
                                                  fieldSet: {index: index},
                                              })}
                                          </strong>
                                          <div className="shelf-actions">
                                              <OverlayTrigger
                                                  placement={
                                                      isSmallDevice
                                                          ? 'top'
                                                          : 'right'
                                                  }
                                                  overlay="Copy Shelf">
                                                  <Button
                                                      disabled={isLimitReached}
                                                      tabIndex={-1}
                                                      variant="link"
                                                      onClick={() =>
                                                          copyShelfDialog(index)
                                                      }>
                                                      <Icon iconName="Options-Copy.svg" />
                                                  </Button>
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                  placement={
                                                      isSmallDevice
                                                          ? 'top'
                                                          : 'right'
                                                  }
                                                  overlay="Delete Shelf">
                                                  <Button
                                                      tabIndex={-1}
                                                      variant="link"
                                                      onClick={() => {
                                                          deleteShelfDialog(
                                                              index
                                                          );
                                                      }}>
                                                      <Icon iconName="Options-Delete.svg" />
                                                  </Button>
                                              </OverlayTrigger>
                                          </div>
                                      </section>
                                  </Col>
                                  <Col>
                                      <GenericFields
                                          fields={shelfFieldset.fields}
                                          fieldsetName={shelfFieldset.name}
                                          fieldsetTitle={shelfFieldset.title}
                                          fieldSetIndex={index}
                                          indexedValues={indexedValue}
                                          selectHandler={(
                                              name: string,
                                              value: number
                                          ) => {
                                              const shelves = [
                                                  ...values[
                                                      String(fieldsetName)
                                                  ],
                                              ];

                                              shelves[Number(index)][
                                                  String(name)
                                              ] = value;

                                              void setFieldValue(
                                                  fieldsetName,
                                                  shelves
                                              );
                                          }}
                                      />
                                  </Col>
                              </Row>

                              {index <= shelfFieldsets.length - 2 ? (
                                  <hr />
                              ) : null}
                          </React.Fragment>
                      );
                  })
                : null}
            <Row className="buttons-container-shelf-row">
                <Col className="buttons-container-shelf" xs={4}>
                    <CBCButton
                        tabIndex={-1}
                        iconName="Button-Add-Shelf.svg"
                        disabled={isLimitReached}
                        onClick={() =>
                            addShelfDialog(
                                <AddShelfDialog fieldsetName={fieldsetName} />
                            )
                        }>
                        Add a Shelf
                    </CBCButton>
                </Col>
                {values[String(fieldsetName)]?.length > 0 ? (
                    <>
                        <Col className="buttons-container-shelf" xs={4}>
                            <CBCButton
                                tabIndex={-1}
                                iconName="Button-Auto-Positions.svg"
                                onClick={autoPosition}>
                                Auto Positions
                            </CBCButton>
                        </Col>
                        <Col className="buttons-container-shelf" xs={4}>
                            <ConvertButton
                                fieldsetName={fieldsetName}
                                handleConvertToPercentage={togglePositionType(
                                    fieldsetName,
                                    POSITION_TYPES.PERCENTAGE
                                )}
                                handleConvertToMM={togglePositionType(
                                    fieldsetName,
                                    POSITION_TYPES.DECIMAL
                                )}
                            />
                        </Col>
                    </>
                ) : null}
            </Row>

            {dialog}
        </>
    );
};
