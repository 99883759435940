import React, {useMemo} from 'react';
import {Drawer} from 'components/customer/Product/Drawer/Drawer';
import {Col, Row} from 'react-bootstrap';

import type {
    Field as FieldType,
    DrawerFieldset as Fieldset,
} from 'shared/types';
import {useRecalculateFaceHeight} from 'components/customer/Product/Drawer/helpers/useRecalculateFaceHeight';
import {DrawerType} from 'components/customer/Product/Drawer/DrawerType';
import {DrawerRunner as DrawerRunnerComponent} from 'components/customer/Product/Drawer/DrawerRunner';
import {DrawerParameters} from 'components/customer/Product/entity/DrawerParameters';

interface DrawerRunnerType {
    field: FieldType;
    fieldset: Fieldset;
    reducedLabelColumnWidth?: boolean;
}

export const DrawerRunner = ({
    field,
    fieldset,
    reducedLabelColumnWidth = false,
}: DrawerRunnerType): JSX.Element => {
    const drawerParams = useMemo<DrawerParameters>(() => {
        const params: DrawerParameters = {
            isDrawerComponent: true,
            drawerStyleFieldName: '',
            drawerFaceHeightFieldName: '',
            drawerGapFieldName: '',
            totalDrawerHeight: '',
            drawerTopMarginFieldName: 'cabinet_drawer_top',
            drawerBottomMarginFieldName: 'cabinet_drawer_bottom',
            fingerPullFieldName: 'fingerPull_styles',
        };

        if (field) {
            params.maxRunnerDepth = field.options.maxRunnerDepth;
            params.drawerRunnerFieldName = field.name;
            params.drawerTypeFieldName = field.options.drawerTypeFieldName;
            params.drawerStyleFieldName = field.options.drawerStyleFieldName;
            params.drawerFaceHeightFieldName =
                field.options.drawerHeightFieldName;
        }

        if (fieldset && fieldset.quantity) {
            params.isDrawerComponent = false;
            params.quantity = fieldset.quantity;
            params.drawerGapFieldName = fieldset.options.drawerGap;
            params.totalDrawerHeight = fieldset.options.totalDrawerHeight;
            params.drawerGapFieldName = fieldset.options.drawerGap;
        }

        return params;
    }, [field, fieldset]);

    useRecalculateFaceHeight({
        isDrawerComponent: drawerParams.isDrawerComponent,
        drawerGapFieldName: drawerParams.drawerGapFieldName,
        totalDrawerHeight: drawerParams.totalDrawerHeight,
        drawerTopMarginFieldName: drawerParams.drawerTopMarginFieldName,
        drawerBottomMarginFieldName: drawerParams.drawerBottomMarginFieldName,
    });

    return useMemo<JSX.Element>(() => {
        if (drawerParams) {
            if (drawerParams.isDrawerComponent) {
                const labelColSpan = reducedLabelColumnWidth ? 4 : 5;

                return (
                    <Row>
                        <Col xs={8}>
                            <DrawerType
                                isDrawerComponent={
                                    drawerParams.isDrawerComponent
                                }
                                fieldName={drawerParams.drawerTypeFieldName}
                                labelColSpan={labelColSpan}
                            />
                            <DrawerRunnerComponent
                                isDrawerComponent={
                                    drawerParams.isDrawerComponent
                                }
                                fieldName={drawerParams.drawerRunnerFieldName}
                                drawerTypeFieldName={
                                    drawerParams.drawerTypeFieldName
                                }
                                drawerFaceHeightFieldName={
                                    drawerParams.drawerFaceHeightFieldName
                                }
                                fingerPullFieldName={
                                    drawerParams.fingerPullFieldName
                                }
                                drawerDepth={drawerParams.maxRunnerDepth}
                                labelColSpan={labelColSpan}
                            />
                        </Col>
                    </Row>
                );
            }

            return (
                <>
                    {Array.from(Array(drawerParams.quantity).keys()).map(
                        (index) => {
                            return (
                                <Drawer
                                    key={index}
                                    runnerIndex={index}
                                    drawerParams={drawerParams}
                                    showLine={
                                        drawerParams.quantity !== index + 1
                                    }
                                />
                            );
                        }
                    )}
                </>
            );
        }

        return <></>;
    }, [drawerParams]);
};
