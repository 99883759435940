import React from 'react';
import {GenericFields} from 'components/customer/Product';
import styled from 'styled-components';
import type {Field, Fieldset} from 'shared/types';

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px 25px;
    margin-bottom: 20px;
    & > .row {
        margin-left: 0;
        width: min-content;
        background: #eee;
        border-radius: 8px;
        &.include_drawer_faces_label {
            background: #fff;
            width: 100%;
            order: 1;
        }
    }
`;

interface ProductionOptionsProps {
    fields: Field[];
    fieldset: Fieldset;
    fieldsetName: string;
}

export const ProductionOptions = ({
    fields,
    fieldset,
    fieldsetName,
}: ProductionOptionsProps) => {
    return (
        <>
            <hr />
            <StyledContainer>
                <GenericFields
                    fields={fields}
                    fieldset={fieldset}
                    fieldsetName={fieldsetName}
                    formLabelSpanOverride={12}
                    colSpanFull
                />
            </StyledContainer>
        </>
    );
};
