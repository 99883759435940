import {Product} from 'components/customer/Product/entity';
import {getCabinetTop} from 'service';

export const checkCabinetTopRestriction = async (product: Product) => {
    if (
        product.available_options &&
        !product.available_options.is_cabinet_top_available &&
        product.hasOwnProperty('cabinet_top')
    ) {
        const cabinetTops = await getCabinetTop({});

        if (cabinetTops && cabinetTops.length > 0) {
            const selectedCabinetTop = cabinetTops.find(
                (cabinetTop) => cabinetTop.id == product.cabinet_top
            );

            if (selectedCabinetTop) {
                return `<strong>${selectedCabinetTop.name}</strong> (Cabinet Top)`;
            }
        }
    }
};
