import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {CBCButton, getFieldValue, Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {OverlayTrigger} from 'shared';
import {useDrillings} from 'hooks';
import {GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';

export const Drillings = ({fields, fieldset}) => {
    const isSmallDevice = isDeviceSmall();
    const {values} = useFormikContext();
    const {addDrillingSet, updateDrillingSet, drillingFieldsets, fieldHandler} =
        useDrillings(fields, fieldset);

    return (
        <Row>
            <Col className="drillings-content">
                {drillingFieldsets.length ? (
                    drillingFieldsets.map((drilling, index) => {
                        const indexedValue = values.drillings
                            ? values.drillings[index]
                            : {};

                        return (
                            <React.Fragment key={index}>
                                <Row>
                                    <Col className="shelf" md={5}>
                                        <section
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <strong>
                                                {getFieldValue(drilling.title, {
                                                    fieldSet: {index: index},
                                                })}
                                            </strong>
                                            <div className="shelf-actions">
                                                <OverlayTrigger
                                                    placement={
                                                        isSmallDevice
                                                            ? 'top'
                                                            : 'right'
                                                    }
                                                    overlay="Copy hole set">
                                                    <Button
                                                        tabIndex="-1"
                                                        variant="link"
                                                        onClick={() =>
                                                            updateDrillingSet(
                                                                index
                                                            )
                                                        }>
                                                        <Icon iconName="Options-Copy.svg" />
                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement={
                                                        isSmallDevice
                                                            ? 'top'
                                                            : 'right'
                                                    }
                                                    overlay="Delete hole set">
                                                    <Button
                                                        tabIndex="-1"
                                                        variant="link"
                                                        onClick={() => {
                                                            updateDrillingSet(
                                                                index,
                                                                true
                                                            );
                                                        }}>
                                                        <Icon iconName="Options-Delete.svg" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        </section>
                                    </Col>
                                    <Col>
                                        <GenericFields
                                            fields={drilling.fields}
                                            fieldsetName={drilling.name}
                                            fieldsetTitle={drilling.title}
                                            indexedValues={indexedValue}
                                            selectHandler={(name, value) =>
                                                fieldHandler(name, value, index)
                                            }
                                        />
                                    </Col>
                                </Row>

                                <hr />
                            </React.Fragment>
                        );
                    })
                ) : (
                    <>
                        <strong className="no-drillings">
                            {fieldset.hasOwnProperty('options') &&
                                fieldset.options.hasOwnProperty('emptyText') &&
                                fieldset.options.emptyText}
                        </strong>
                        <br />
                    </>
                )}

                <CBCButton
                    onClick={addDrillingSet}
                    iconName="Button-Add-Shelf.svg"
                    className="btn button-blue short-button">
                    Add Shelf Hole Set
                </CBCButton>
            </Col>
        </Row>
    );
};
