import { GenericFields } from 'components/customer/Product';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { validSubpanels } from 'shared/helpers';
import { MoreInfo } from 'shared';

export const DoorSubPanels = ({fields, fieldset, isQFP = false, selectHandler = false}) => {
    
    const { values, setFieldValue } = useFormikContext();

    const [checkBoxes, setCheckBoxes] = useState();

    const nonSubPanelFields = fields.filter( field => field.name !== "sub_panel" );
    const subPanelField = fields.find( field => field.name == "sub_panel" );
    
    const horizontalField = subPanelField && subPanelField.hasOwnProperty("options") ? subPanelField.options.horizontalAmountField : 0;
    const verticalField = subPanelField && subPanelField.hasOwnProperty("options") ? subPanelField.options.verticalAmountField : 0;

    useEffect(() => {
        if (horizontalField && verticalField) {
            let horizontalAmount = values[horizontalField] ? values[horizontalField] : 0;
            let verticalAmount = values[verticalField] ? values[verticalField] : 0;

            horizontalAmount = horizontalAmount >= 3 ? 3 : horizontalAmount + 1;
            verticalAmount = verticalAmount >= 3 ? 3 : verticalAmount + 1;

            const positions = Array(horizontalAmount).fill(Array(verticalAmount).fill(true));
            const validSubPanels = validSubpanels(positions);
            
            setCheckBoxes(validSubPanels);
        }
    }, [ 
        values[horizontalField], 
        values[verticalField],
        values.cabinet_type ? values.cabinet_type : 0
    ]);

    const handleChange = (i, j, checked) => {
        let panels = JSON.parse(JSON.stringify(values.glassSubPanels ? values.glassSubPanels : [[false]]));

        panels = validSubpanels(panels);

        panels[i][j] = checked;

        if (selectHandler)
            selectHandler('glassSubPanels', panels)
        else
            setFieldValue("glassSubPanels", panels);
    }

    return (
        <>
            {
                checkBoxes ?
                    <>
                        { isQFP ? <></> : <hr /> }
                        <FormGroup as={Row} controlId='subpanels'>
                            <FormLabel column md={5}
                                style={{paddingRight:"24px"}}>
                                { subPanelField.displayName }
                                {subPanelField.hasOwnProperty('options') &&
                                subPanelField.options.hasOwnProperty('moreInfo') &&
                                subPanelField.options.moreInfo ? (
                                    <MoreInfo
                                        info={subPanelField.options.moreInfo}
                                    />
                                ) : (
                                    <></>
                                )}
                            </FormLabel>
                            <Col>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {
                                        checkBoxes.map((horizontal, i) => {
                                            return (
                                                <div key={i} style={{display: 'flex', marginBottom: '4px'}}>
                                                    {
                                                        horizontal.map((subPanel, j) => {
                                                            if (subPanel) {
                                                                return <input 
                                                                            type="checkbox" 
                                                                            key={j} 
                                                                            checked={ values.glassSubPanels && values.glassSubPanels[i] && values.glassSubPanels[i][j] ? values.glassSubPanels[i][j] : false } 
                                                                            onChange={(e) => handleChange(i, j, e.target.checked) } 
                                                                            style={{margin: "5px 5px 0px 0px"}}
                                                                        />;
                                                            } else {
                                                                return <React.Fragment key={j}></React.Fragment>;
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </FormGroup>
                    </>
                : <></>
            }
            <hr style={{ opacity : isQFP ? .1 : 1 }} />
            <GenericFields
                fields={nonSubPanelFields}
                fieldsetName={fieldset.name}
                fieldsetTitle={fieldset.title}
                fieldsetLayout={ isQFP ? "" : "compound" }
                selectHandler={selectHandler}
                hasFullWidth={ isQFP }
            />
        </>
    );
}