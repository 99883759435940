import {Structure} from 'components/customer/Product/entity';

/**
 * @typedef {Object} Checks
 * @property {boolean} hasDoor Has door borders
 * @property {boolean} hasDrawer Has drawer borders
 */

/**
 * Simply checks the form structure if door and drawer
 * exists or not.
 *
 * @param {Structure[]} structures form structure of the product
 * @return {Checks}
 */
export const checkProductHasDoorAndDrawerBorders = (
    structures: Structure[]
) => {
    const checks = {
        hasDoor: false,
        hasDrawer: false,
    };

    if (structures && Array.isArray(structures)) {
        // Checking if has door
        structureLoop: for (const structure of structures) {
            if (structure.name == 'Doors') {
                fieldsetLoop: for (const fieldset of structure.fieldsets) {
                    if (fieldset.name == 'border_widths') {
                        checks.hasDoor = true;
                        break fieldsetLoop;
                    }
                }
                if (checks.hasDoor) {
                    break structureLoop;
                }
            }
        }

        // checking if has drawer
        structureLoop: for (const structure of structures) {
            if (structure.name == 'Drawers') {
                fieldsetLoop: for (const fieldset of structure.fieldsets) {
                    if (fieldset.name == 'border_widths') {
                        checks.hasDrawer = true;
                        break fieldsetLoop;
                    }
                }
                if (checks.hasDrawer) {
                    break structureLoop;
                }
            }
        }
    }

    return checks;
};
