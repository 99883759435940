import {Structure} from 'components/customer/Product/entity';

/**
 * @param {Structure[]} productDetails
 */
export function* productConfigIterator(productDetails: Structure[]) {
    let structureIndex = 0;
    for (const config of productDetails) {
        if (config.hasOwnProperty('fieldsets') && config.fieldsets) {
            for (const fieldset of config.fieldsets) {
                if (fieldset.hasOwnProperty('fields') && fieldset.fields) {
                    let i = 0;
                    for (const field of fieldset.fields) {
                        yield {
                            field: {...field, index: i},
                            fieldset,
                            structureIndex,
                        };
                        i++;
                    }
                } else
                    console.log('field set does not have any fields defined');
            }
        } else
            console.log('product configuration does not have any field sets');

        structureIndex++;
    }
}
