import React from 'react';
import {GenericFields} from './GenericFields';

/**
 * This rotates the edge fields supplied for the hack for NZPG's manual panels.
 *
 * This is specifically requested by the backend on a small number of products and is a HACK.
 *
 * @param {Object[]} fields
 * @param {Object} fieldset
 * @param {boolean} isQFP
 * @param {boolean|Function} selectHandler
 *
 * @return {React$Element<any>}
 */
export const NZPGRotatedEdgeFields = ({
    fields,
    fieldset,
    isQFP = false,
    selectHandler = false,
}) => {
    let rotatedFields;

    if (fields.length != 4) {
        // Cannot rotate, so just set
        rotatedFields = fields;
    } else {
        const topField = fields.find((field) => {
            return (
                field.name == 'cabinet_edge_w1' ||
                field.name == 'panel_edge_top'
            );
        });

        const bottomField = fields.find((field) => {
            return (
                field.name == 'cabinet_edge_w2' ||
                field.name == 'panel_edge_bottom'
            );
        });

        const leftField = fields.find((field) => {
            return (
                field.name == 'cabinet_edge_l2' ||
                field.name == 'panel_edge_left'
            );
        });

        const rightField = fields.find((field) => {
            return (
                field.name == 'cabinet_edge_l1' ||
                field.name == 'panel_edge_right'
            );
        });

        const materialField = topField.options.materialField;
        const edgeFieldType = topField.type;

        rotatedFields = [
            {
                name: topField.name,
                displayName: fields[0].displayName,
                type: edgeFieldType,
                value: null,
                options: {
                    materialField: materialField,
                    adjacentFields: [leftField.name, rightField.name],
                },
            },
            {
                name: bottomField.name,
                displayName: fields[1].displayName,
                type: edgeFieldType,
                value: null,
                options: {
                    materialField: materialField,
                    adjacentFields: [leftField.name, rightField.name],
                },
            },
            {
                name: leftField.name,
                displayName: fields[2].displayName,
                type: edgeFieldType,
                value: null,
                options: {
                    materialField: materialField,
                    adjacentFields: [topField.name, bottomField.name],
                },
            },
            {
                name: rightField.name,
                displayName: fields[3].displayName,
                type: edgeFieldType,
                value: null,
                options: {
                    materialField: materialField,
                    adjacentFields: [topField.name, bottomField.name],
                },
            },
        ];
    }

    return (
        <GenericFields
            fields={rotatedFields}
            fieldsetName={fieldset.name}
            fieldsetLayout={fieldset.options && fieldset.options.layout}
            selectHandler={selectHandler}
            isQFP={isQFP}
        />
    );
};
