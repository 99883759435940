import {useFormikContext} from 'formik';
import React from 'react';
import {Image} from 'react-bootstrap';
import {DrawerFields} from 'components/customer/Product/entity/Drawer';
import {useRunnerRequestParams} from 'components/customer/Product/Drawer/helpers/useRunnerRequestParams';
import {useGetRunnerQuery} from 'components/customer/Product/store/drawerRunnerApi';

interface DrawerThumbnailInterfaec {
    runnerIndex: number;
    drawerTypeFieldName: string;
    drawerFaceHeightFieldName: string;
    fingerPullFieldName: string;
    drawerDepth: string;
}

export const DrawerThumbnail = ({
    runnerIndex,
    drawerTypeFieldName,
    drawerFaceHeightFieldName,
    fingerPullFieldName,
    drawerDepth,
}: DrawerThumbnailInterfaec) => {
    const {values} = useFormikContext<DrawerFields>();
    const {params} = useRunnerRequestParams(
        runnerIndex,
        false,
        drawerTypeFieldName,
        drawerDepth,
        drawerFaceHeightFieldName,
        fingerPullFieldName
    );

    const {runner} = useGetRunnerQuery(params, {
        selectFromResult: ({data}) => ({
            runner: data?.find(
                (runner) =>
                    runner.id == values.drawers[runnerIndex].drawer_runner_specs
            ),
        }),
    });

    if (typeof runner === 'undefined' || (runner && runner.imageName == null)) {
        return <></>;
    }

    return (
        <section className="drawer-thumbnail">
            <Image src={`${runner.image}`} rounded />
        </section>
    );
};
