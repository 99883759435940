import {
    DrawerRunner,
    FIELDSETS,
    FIELDSET_LAYOUT,
} from 'components/customer/Product';
import {useProductContext} from 'contexts';
import React, {useEffect, useState, useMemo} from 'react';
import {Col, Form, FormGroup, Row} from 'react-bootstrap';
import {Field, MoreInfo} from 'shared';
import excel from 'shared/Excel';
import {useFormikContext} from 'formik';
import {
    isDeviceSmall,
    isDeviceMid,
    isDeviceLarge,
    useTabletSize,
} from 'shared/helpers/DeviceSize';
import styled from 'styled-components';

const StyledLabel = styled.label`
    font-weight: bold;
    display: block;
    white-space: nowrap;
    margin-top: 9px;
`;

export const GenericFields = ({
    fields,
    fieldsetName,
    fieldsetLayout,
    fieldsetTitle,
    fieldSetIndex = null,
    fieldSetMoreInfo = null,
    selectHandler = false,
    indexedValues = false,
    isQFP = false,
    fieldsetOptions = false,
    hasFullWidth = false,
    colSpanFull = false,
    preview = false,
    cancelButton = null,
    formLabelSpanOverride = null,
    buttonSlot = null,
}: {
    fieldSetMoreInfo?: number,
    fields: Object,
    fieldsetName: String,
    fieldsetLayout: String,
    buttonSlot?: JSX.Element,
    indexedValues?: Object,
    selectHandler?: Function,
}) => {
    const isSmallDevice = isDeviceSmall();
    const deviceIsMedium = isDeviceMid(false);
    const largeSizedDevice = isDeviceLarge();
    const isTabletSize = useTabletSize();

    const {getMaterialOptions, addInitialValue} = useProductContext();
    const {values, setFieldTouched, setFieldValue} = useFormikContext();

    const materialOptions = getMaterialOptions();
    const [collapsed, setCollapsed] = useState(() => {
        // mainly used for qfp only
        if (fieldsetOptions.hasOwnProperty('collapsed')) {
            return excel.calculate(fieldsetOptions.collapsed, values);
        }

        return false;
    });

    const handleCollapseEvent = () => {
        if (isQFP && fieldsetOptions.hasOwnProperty('collapsible')) {
            setCollapsed(!collapsed);
        }
    };

    let span = isSmallDevice || deviceIsMedium || colSpanFull ? 12 : 8;
    let fieldsToStack = [];
    let fieldHeaderName = null;
    const formLabelSpan = formLabelSpanOverride || 5;

    const isFullWidth = [
        FIELDSETS.COMMENTS,
        FIELDSETS.SHELVES,
        FIELDSETS.DRILLINGS,
        FIELDSETS.EDGINGS,
        FIELDSETS.UPPER_SHEPVES,
        FIELDSETS.MIDDLE_SHELVES,
        FIELDSETS.LOWER_SHELVES,
        FIELDSETS.SIMPLE_SHELVES,
        FIELDSETS.SHELF_ADVANCED_CHECKBOX,
        FIELDSETS.ADDITIONAL,
    ];

    if (preview) {
        isFullWidth.push(FIELDSETS.FINGERPULL);
        isFullWidth.push(FIELDSETS.ADDITIONAL_SIZES);
        isFullWidth.push(FIELDSETS.OVEN);
        isFullWidth.push(FIELDSETS.RANGEHOOD);
        isFullWidth.push(FIELDSETS.MICROWAVE);
    }

    if (
        fieldsetName === FIELDSETS.DOOR_FIELDS &&
        materialOptions.cabinet_door &&
        materialOptions.cabinet_door.advanced
    ) {
        span = 12;
    }

    if (
        (isSmallDevice || isTabletSize) &&
        fieldsetName === FIELDSETS.VARIATIONS
    ) {
        span = 11;
    }

    if (isFullWidth.includes(fieldsetName)) {
        span = 12;
    }

    if (fieldsetLayout === FIELDSET_LAYOUT.COMPOUND) {
        span = 12;
    }

    if (fieldsetLayout === FIELDSET_LAYOUT.MID_RAILS) {
        span = 12;
    }

    if (isQFP || hasFullWidth) {
        span = 12;
    }

    if (isSmallDevice || !isQFP) {
        fieldsToStack = [
            'cabinet_include_assembly',
            'cabinet_include_hardware',
            'include_drawer_faces',
            'cabinet_adjustable_legs',
            'include_drawer_faces_label',
            'cabinet_comment',
        ];
    }

    useEffect(() => {
        if (isQFP) {
            fields &&
                fields.map((field) => {
                    addInitialValue(field.name, field.value);
                });
        }
    }, []);

    const formFields = useMemo(() => {
        return fields && Array.isArray(fields)
            ? fields.map((field, i) => {
                  if (field.name === 'drawer_runner_specs') {
                      return (
                          <DrawerRunner
                              key={i}
                              field={field}
                              reducedLabelColumnWidth
                          />
                      );
                  }

                  if (
                      typeof field.type === 'string' &&
                      field.type.indexOf('hidden') > -1
                  ) {
                      return <React.Fragment key={i}></React.Fragment>;
                  }

                  let selectHandlerOverride;
                  let extraHandler;
                  if (field.name === 'fingerPull_styles') {
                      const fingerPullDefaultHandler = (
                          name,
                          value,
                          options
                      ) => {
                          const selectedOption = options.find(
                              (option) => option.id == value
                          );

                          if (selectedOption) {
                              // NOTE: calling both setFieldValue and setTouched
                              // is essential. Keep a lookout for function/way that
                              // might do both of this with single call.

                              if (
                                  selectedOption.drawerGap &&
                                  values.hasOwnProperty('cabinet_drawer_gap')
                              ) {
                                  setFieldValue(
                                      'cabinet_drawer_gap',
                                      selectedOption.drawerGap
                                  );
                                  setFieldTouched('cabinet_drawer_gap', true);
                              }

                              if (
                                  selectedOption.topMargin &&
                                  values.hasOwnProperty('cabinet_drawer_top')
                              ) {
                                  setFieldValue(
                                      'cabinet_drawer_top',
                                      selectedOption.topMargin
                                  );
                                  setFieldTouched('cabinet_drawer_top', true);
                              }

                              if (
                                  selectedOption.topMargin &&
                                  values.hasOwnProperty('cabinet_door_top')
                              ) {
                                  setFieldValue(
                                      'cabinet_door_top',
                                      selectedOption.topMargin
                                  );
                                  setFieldTouched('cabinet_door_top', true);
                              }
                          }
                      };

                      selectHandlerOverride = (name, value, options) => {
                          fingerPullDefaultHandler(name, value, options);
                          setFieldValue(name, value);
                      };

                      extraHandler = fingerPullDefaultHandler;
                  } else {
                      selectHandlerOverride = selectHandler;
                  }

                  if (fieldsetName === FIELDSET_LAYOUT.DRAWER_EDGES) {
                      if (isQFP) {
                          span = 10;
                          fieldHeaderName = (
                              <Col xs="2">
                                  {field.name === 'drawer_edge_top' ? (
                                      <StyledLabel>
                                          Drawer {fieldSetIndex + 1}
                                      </StyledLabel>
                                  ) : null}
                              </Col>
                          );
                      } else {
                          span = 9;
                      }
                  }

                  return (
                      <Field
                          key={i}
                          field={field}
                          dataSource={
                              field.hasOwnProperty('dataSource')
                                  ? field.dataSource
                                  : false
                          }
                          fieldSpan={span}
                          formControlSpan={
                              isQFP &&
                              fieldsetOptions.hasOwnProperty('collapsible')
                                  ? 12
                                  : formLabelSpan
                          }
                          selectHandler={
                              selectHandlerOverride ||
                              (field.hasOwnProperty('action') && field.action)
                          }
                          extraHandler={extraHandler}
                          indexedValues={indexedValues}
                          fieldsetName={fieldsetName}
                          fieldSetIndex={fieldSetIndex}
                          isQFP={isQFP}
                          isStackFields={
                              [
                                  'cabinet_note',
                                  'drilling_offset_y',
                                  ...fieldsToStack,
                              ].includes(field.name) ||
                              (field.name == 'cabinet_include_hardware' &&
                                  isQFP)
                          }
                          cancelButton={cancelButton}
                          buttonSlot={buttonSlot}
                          fieldHeaderName={fieldHeaderName}
                      />
                  );
              })
            : null;
    }, [fields, indexedValues, values]);

    if (fieldsetLayout === FIELDSET_LAYOUT.COMPOUND && fieldsetTitle) {
        return (
            <Row>
                <Col md={deviceIsMedium ? 12 : 9}>
                    <FormGroup>
                        <Form.Label className="fieldset-title">
                            {fieldsetTitle}
                        </Form.Label>
                        {formFields}
                    </FormGroup>
                </Col>
            </Row>
        );
    }

    if (fieldsetLayout === FIELDSET_LAYOUT.MID_RAILS && fieldsetTitle) {
        return (
            <Row>
                <Col sm={12}>
                    <FormGroup>
                        <Form.Label
                            style={
                                isQFP
                                    ? {
                                          display: 'flex',
                                          position: 'relative',
                                          width: '50%',
                                          marginTop: 7,
                                      }
                                    : {}
                            }>
                            {fieldsetTitle}
                            {fieldSetMoreInfo ? (
                                <MoreInfo info={fieldSetMoreInfo} />
                            ) : null}
                        </Form.Label>
                        <hr style={{opacity: 0.15, marginTop: 0}} />
                        <div style={{paddingLeft: 8}}>{formFields}</div>
                    </FormGroup>
                </Col>
            </Row>
        );
    }

    if (isQFP && fieldsetLayout !== FIELDSET_LAYOUT.COMPOUND && fieldsetTitle) {
        let colSpan = deviceIsMedium ? 6 : 2;

        if (
            fieldsetName === FIELDSETS.DOOR_FIELDS ||
            fieldsetName == FIELDSETS.DOOR_EDGES ||
            fieldsetName == FIELDSETS.MID_RAILS ||
            fieldsetName == FIELDSETS.MATERIAL_OPTIONS ||
            fieldsetName == FIELDSETS.BORDER_WIDTHS ||
            fieldsetName == FIELDSETS.COMMENTS ||
            fieldsetName == FIELDSETS.VERT_MID_RAILS ||
            fieldsetName == FIELDSETS.HORI_MID_RAILS
        ) {
            colSpan = largeSizedDevice ? colSpan : 3;
        }

        if (fieldsetName == FIELDSETS.ADDITIONAL) {
            colSpan = largeSizedDevice ? 4 : 5;
        }

        if (fieldsetName == FIELDSETS.DRAWER_FIELDS) {
            colSpan = largeSizedDevice ? 3 : 4;
        }

        return (
            <Col sm={colSpan}>
                <div
                    className="fieldset-title"
                    onClick={handleCollapseEvent}
                    style={{
                        cursor: fieldsetOptions.hasOwnProperty('collapsible')
                            ? 'pointer'
                            : 'initial',
                        userSelect: 'none',
                    }}>
                    <strong>
                        {fieldsetTitle}{' '}
                        {fieldsetOptions.hasOwnProperty('collapsible')
                            ? ` (Click to ${collapsed ? 'Expand' : 'Collapse'})`
                            : ''}
                    </strong>
                </div>
                <br />
                {collapsed ? null : formFields}
            </Col>
        );
    }

    return formFields;
};
