// @flow
import {useShelvesPreview} from 'hooks';
import React from 'react';

import type {ShelvesPreviewOption, StickyPosition} from 'shared/types';

export const ShelvesPreview = ({
    previewOptions,
}: {
    previewOptions: ShelvesPreviewOption,
}): React$Element<any> => {
    const {position, canvasContainer} = useShelvesPreview(previewOptions);

    return (
        <div className="panelContainer" style={position} id={canvasContainer} />
    );
};
