import {productConfigIterator} from 'components/customer/Product/helpers/productConfigIterator';
import {Structure} from 'components/customer/Product/entity';
import {validator} from 'shared/validator';
import {object, string as yupString} from 'yup';

export const getFormSchema = (
    productDetails: Structure[],
    allFields: string[]
) => {
    let formSchema = object();
    const configIterator = productConfigIterator(productDetails);

    let config;
    while ((config = configIterator.next())) {
        if (typeof config.value === 'undefined') break;

        const {field, fieldset} = config.value;

        const rule = validator(field, fieldset, allFields);
        const fieldName = field['name'];
        if (rule)
            formSchema = formSchema.shape({
                [fieldName]: rule,
            });
    }

    formSchema = formSchema.shape({
        custom_colour_ext: yupString().when(
            'exterior_colour_name',
            ([exteriorColourName], schema) => {
                if (
                    String(exteriorColourName)
                        .toLowerCase()
                        .indexOf('custom colour') > -1
                ) {
                    return schema.test(
                        'requiredExteriorCustomColour',
                        'Please enter custom exterior colour name',
                        (colour) => colour && colour?.trim() != ''
                    );
                }
            }
        ),
        custom_colour_carc: yupString().when(
            'carcase_colour_name',
            ([carcaseColourName], schema) => {
                if (
                    String(carcaseColourName)
                        .toLowerCase()
                        .indexOf('custom colour') > -1
                ) {
                    return schema.test(
                        'requiredCarcaseCustomColour',
                        'Please enter custom carcase colour name',
                        (colour) => colour && colour?.trim() != ''
                    );
                }
            }
        ),
    });

    return formSchema;
};
