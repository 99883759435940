import {GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import React, {useCallback, useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Field, Fieldset} from 'components/customer/Product/entity';
import {
    DrawerPanelEdge,
    useComponentDrawerEdge,
} from 'hooks/Product/DrawerPanelEdge';
import {useAppContext} from 'contexts';

interface DrawerPanelEdgeInterface {
    fields: Field[];
    fieldset: Fieldset;
    isQFP: boolean;
    selectHandler?: (name: string, value: DrawerPanelEdge[]) => void;
}

export const DrawerEdges = ({
    fieldset,
    isQFP = false,
    selectHandler,
}: DrawerPanelEdgeInterface) => {
    const {userProfile} = useAppContext();
    const {values} = useFormikContext<{
        drawer_panel_edges: DrawerPanelEdge[];
        [key: string]: number | DrawerPanelEdge[];
    }>();

    const {fieldName, fieldsets} = useComponentDrawerEdge(
        fieldset,
        selectHandler
    );

    const layout = fieldset?.options?.layout || '';

    const getIndexedValue = useCallback(
        (index: number) => {
            const fieldName = fieldset.name;
            const fieldValues = values[String(fieldName)] as DrawerPanelEdge[];

            const value = fieldValues?.find((_item, i) => i === index);

            return value || {};
        },
        [values[String(fieldName)], fieldset.name]
    );

    const renderHeader = (index: number) => (
        <>
            {index !== 0 && <hr style={{margin: 0, paddingTop: '4px'}} />}
            {!isQFP && (
                <Col>
                    <label className="form-label">Drawer {index + 1} </label>
                </Col>
            )}
        </>
    );

    const drawerEdgeContent = useMemo(() => {
        if (!userProfile.allowDrawerFaceEdgeFinish) {
            return null;
        }

        return (
            <Row>
                <Col>
                    {fieldsets.map((set, index) => {
                        const indexedValue = getIndexedValue(index);
                        const {id = index}: {id?: number} = indexedValue;
                        return (
                            <React.Fragment key={id}>
                                {renderHeader(index)}
                                <GenericFields
                                    fields={set.fields}
                                    isQFP={isQFP}
                                    hasFullWidth={isQFP}
                                    fieldsetName={set.name}
                                    fieldSetIndex={index}
                                    indexedValues={indexedValue}
                                    fieldsetLayout={layout}
                                />
                            </React.Fragment>
                        );
                    })}
                </Col>
            </Row>
        );
    }, [fieldsets, userProfile, values[String(fieldName)]]);

    return drawerEdgeContent;
};
