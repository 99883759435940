import {Drawer} from 'components/customer/Product/entity/Drawer';
import {DrawerRunner} from 'components/customer/Product/entity/DrawerRunner';

export const getFaceHeight = (
    drawers: Drawer[],
    runnerIndex: number,
    totalDrawerHeight: number,
    cabinetDrawerBottom = 0,
    cabinetDrawerTop = 0,
    cabinetDrawerGap = 0
): number => {
    let offset = 0;
    let count = drawers.length;

    drawers.forEach((drawer, index) => {
        if (
            (drawer.drawer_face_height > -1 && drawer.drawer_style == 0) ||
            (runnerIndex == drawers.length - 1 && index < runnerIndex)
        ) {
            offset += parseFloat(String(drawer.drawer_face_height));
            count -= 1;
        }
    });

    const height =
        (totalDrawerHeight -
            offset -
            cabinetDrawerBottom -
            cabinetDrawerTop -
            cabinetDrawerGap * (drawers.length - 1)) /
        count;

    if (Number(height) === height && height % 1 !== 0) {
        return parseFloat(height.toFixed(1));
    }

    return height;
};

export const readjustLastAutoHeight = (
    drawers: Drawer[],
    totalDrawerHeight: number,
    cabinetDrawerBottom = 0,
    cabinetDrawerTop = 0,
    cabinetDrawerGap = 0
) => {
    const drawersFiltered = drawers
        .map((drawer, index) => ({...drawer, index}))
        .filter((drawer) => drawer.drawer_style == 1);

    if (drawersFiltered.length) {
        const lastIndex = drawersFiltered.pop().index;

        const totalFaceHeightExceptlast = drawers
            .filter((drawer, index) => index != lastIndex)
            .reduce(
                (partialSum, drawer) => partialSum + drawer.drawer_face_height,
                0
            );

        let height =
            totalDrawerHeight -
            (cabinetDrawerBottom +
                cabinetDrawerTop +
                cabinetDrawerGap * (drawers.length - 1)) -
            totalFaceHeightExceptlast;

        if (Number(height) === height && height % 1 !== 0) {
            height = parseFloat(height.toFixed(1));
        }

        drawers[lastIndex].drawer_face_height = height;
    }

    return drawers;
};

export const toNumber = (value: unknown): number => {
    return typeof value === 'string'
        ? parseFloat(value)
        : typeof value === 'number'
        ? value
        : 0;
};

export const isDrillOnly = (item: DrawerRunner | string | number): boolean => {
    // Assume objects passed are instances of this class
    if (typeof item === 'object' && 'drillOnly' in item) {
        return item.drillOnly;
    }

    // Numbers passed are always invalid.
    if (typeof item == 'number' || item == '-1') {
        return false;
    }

    // Strings will always end with the string defined above
    return /\(drill only\)$/.test(item);
};
