import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {AppState} from 'store/customer/storeSetup';

interface RunnerState {
    [key: string]: boolean;
}
export interface DrawerRunnerState {
    runnersLoadingState: RunnerState;
}

const initialState: DrawerRunnerState = {
    runnersLoadingState: {},
};

const DrawerRunnerSlice = createSlice({
    name: 'drawerRunner',
    initialState,
    reducers: {
        setRunnersLoadingState: (
            state,
            {payload}: PayloadAction<RunnerState>
        ) => {
            state.runnersLoadingState = payload;
        },
    },
});

export const selectRunnersLoadingState = createSelector(
    [(store: AppState) => store.drawerRunner.runnersLoadingState],
    (runnersLoadingState) => {
        return Object.entries(runnersLoadingState).some(
            (runner) => !!runner[1]
        );
    }
);

export const {setRunnersLoadingState} = DrawerRunnerSlice.actions;
export default DrawerRunnerSlice.reducer;
