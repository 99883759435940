import React, {useState, useCallback, useMemo} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import styled from 'styled-components';

import useProductInclusion from 'components/customer/Product/Description/useProductInclusion';
import {useProductContext} from 'contexts';
import {Product} from 'components/customer/Product/entity/Product';
import {InitialValues} from 'components/customer/Product/helpers/mapDefaults';
import {Structure} from 'components/customer/Product/entity/Structure';

const CollapsibleDiv = styled.div<{$collapsed: boolean}>`
    height: ${({$collapsed}) => ($collapsed ? '170px' : 'auto')};
    overflow-y: hidden;
`;

const ShowMore = styled.a`
    font-size: 12px;
    margin-top: 6px;
    cursor: pointer;
`;

interface ProductInclusionProps {
    formValues: InitialValues;
    productData: Product;
    productConfig: Structure[];
    productDataStore: {current?: Product};
    manufacturerDescription?: JSX.Element;
}

const CABINET_TYPE_DOOR = 14;

const ProductInclusion = ({
    formValues,
    productData,
    productConfig,
    productDataStore,
    manufacturerDescription,
}: ProductInclusionProps) => {
    const {productInclusionList} = useProductInclusion({
        formValues,
        cabinet_details: productData?.cabinet_details,
        drill_only_hinge_details: productData?.drill_only_hinge_details,
        productData,
        productConfig,
    });
    const isMobile = isDeviceSmall();
    const {
        getProductInfo,
    }: {
        getProductInfo: (key: string) => string | number | boolean;
    } = useProductContext();

    const productName = useMemo(() => {
        return getProductInfo('name') as string;
    }, [getProductInfo]);

    const productDescription = useMemo(() => {
        return productDataStore.current?.cabinet?.attributes?.description;
    }, [productDataStore]);

    const isDoorProduct = useMemo(() => {
        return (
            productData?.cabinet_type == CABINET_TYPE_DOOR ||
            productName?.toLowerCase() === 'door'
        );
    }, [productData, productName]);

    const [partsCollapseOpen, setPartsCollapseOpen] = useState<boolean>(false);

    const toggleParts = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            setPartsCollapseOpen(!partsCollapseOpen);
        },
        [setPartsCollapseOpen]
    );

    if (productData && !productData.cabinet_details) return null;

    return (
        <>
            <CollapsibleDiv
                $collapsed={
                    isMobile
                        ? isDoorProduct
                            ? false
                            : !partsCollapseOpen
                        : false
                }>
                {productDescription ? (
                    <>
                        <p className="product-inclusions-type">
                            Product Description
                        </p>
                        <div className="product-inclusions-body description">
                            {productDescription}
                        </div>
                    </>
                ) : null}

                {manufacturerDescription}
                {!isDoorProduct && (
                    <>
                        <span className="product-inclusions-title">
                            Product includes:
                        </span>
                        {productInclusionList?.map((inclusion) =>
                            !inclusion.hidden &&
                            inclusion?.items?.length > 0 ? (
                                <React.Fragment key={inclusion.title}>
                                    <p className="product-inclusions-type">
                                        {inclusion.title}
                                    </p>
                                    <div className="product-inclusions-body">
                                        {inclusion.items?.map((item) => (
                                            <div key={item.replace(/\s/g, '')}>
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            ) : null
                        )}
                    </>
                )}
            </CollapsibleDiv>
            {isMobile && !isDoorProduct ? (
                <ShowMore href="#" onClick={toggleParts}>
                    Show {partsCollapseOpen ? 'less' : 'more'}...
                </ShowMore>
            ) : null}
        </>
    );
};

export default ProductInclusion;
