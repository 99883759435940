import {ProductMaterials} from 'components/customer/QFPRedux/helpers/materialHelpers';

export interface MaterialFields {
    hasExterior: boolean;
    hasExteriorDoor: boolean;
    hasCarcase: boolean;
}

export const getMaterialRestrictionDetails = (
    materials: ProductMaterials,
    materialFields: MaterialFields
) => {
    const messages: string[] = [];

    if (
        materials.exterior &&
        materials.exterior.is_hidden &&
        ((materialFields && materialFields.hasExterior) ||
            typeof materialFields == 'undefined')
    ) {
        messages.push(
            `<strong>${materials.exterior.displayName}</strong> (Exterior Material)`
        );
    }

    if (
        materials.edgeExterior &&
        materials.edgeExterior.is_hidden &&
        ((materialFields && materialFields.hasExterior) ||
            typeof materialFields == 'undefined')
    ) {
        messages.push(
            `<strong>${materials.edgeExterior.displayName}</strong> (Exterior Edge Material)`
        );
    }

    if (
        materials.carcase &&
        materials.carcase.is_hidden &&
        ((materialFields && materialFields.hasCarcase) ||
            typeof materialFields == 'undefined')
    ) {
        messages.push(
            `<strong>${materials.carcase.displayName}</strong> (Carcase Material)`
        );
    }

    if (
        materials.edgeCarcase &&
        materials.edgeCarcase.is_hidden &&
        ((materialFields && materialFields.hasCarcase) ||
            typeof materialFields == 'undefined')
    ) {
        messages.push(
            `<strong>${materials.edgeCarcase.displayName}</strong> (Carcase Edge Material)`
        );
    }

    if (
        materials.door &&
        materials.door.is_hidden &&
        ((materialFields && materialFields.hasExteriorDoor) ||
            typeof materialFields == 'undefined')
    ) {
        messages.push(`<strong>${materials.door.name}</strong> (Door Style)`);
    }

    return messages;
};
