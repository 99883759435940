import {FIELDSET_LAYOUT, GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import {useDoorDrillings} from 'hooks';
import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {OverlayTrigger} from 'shared';
import {
    CBCButton,
    getFieldValue,
    hasOption,
    Icon,
    isFieldEnabled,
    isOptionBoolean,
} from 'shared/helpers';
import {isDeviceSmall, isDeviceMediumToLarge} from 'shared/helpers/DeviceSize';

export const DoorDrillings = ({
    fields,
    fieldset,
    isQFP = false,
    selectHandler = false,
}) => {
    const {values, setFieldValue} = useFormikContext();
    const {
        fieldName,
        fieldSets,
        addHingeHole,
        deleteHingeHole,
        createEvenSpace,
    } = useDoorDrillings(fields, fieldset, isQFP, selectHandler);
    const isSmallDevice = isDeviceSmall();
    const mediumToLargeDevice = isDeviceMediumToLarge();
    const noneDoorHang = values?.door_hang == 2;
    const noneHingeStyle =
        values?.drill_only_hinge_name?.toLowerCase() == 'none';

    const [buttonEnabled, setButtonEnabled] = useState(() => {
        if (hasOption(fieldset, 'enabled')) {
            if (isOptionBoolean(fieldset, 'enabled'))
                return fieldset.options.enabled;
            else {
                return isFieldEnabled(fieldset, values);
            }
        }

        return true;
    });

    useEffect(() => {
        try {
            if (
                hasOption(fieldset, 'enabled') &&
                !isOptionBoolean(fieldset, 'enabled')
            ) {
                let enabled_ = isFieldEnabled(fieldset, values);

                setButtonEnabled(enabled_);
            }
        } catch (e) {
            console.log(e);
        }
    }, [values]);

    if (noneDoorHang || noneHingeStyle)
        return (
            <p className="qfp-hinge-hole-none">
                Set{' '}
                {noneDoorHang
                    ? noneHingeStyle
                        ? 'Door Hang and Hinge Style'
                        : 'Door Hang'
                    : 'Hinge Style'}{' '}
                to enable drilling for hinges
            </p>
        );

    return (
        <>
            <Row>
                <Col>
                    {[...fieldSets]
                        .sort((a, b) => a.order - b.order)
                        .map((hingeHole, index) => {
                            const itemIndex =
                                hingeHole.order > 100 ? 1 : hingeHole.order - 1;
                            const indexedValue = values[fieldName]
                                ? values[fieldName][itemIndex]
                                : {};

                            const title = getFieldValue(hingeHole.title, {
                                fieldSet: {index: index},
                            });

                            return (
                                <React.Fragment key={index}>
                                    <Row>
                                        <Col
                                            className="shelf"
                                            md={isQFP ? 4 : 6}>
                                            <section
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <strong>{title}</strong>
                                                <div className="shelf-actions">
                                                    {itemIndex > 1 ? (
                                                        <OverlayTrigger
                                                            placement={
                                                                isSmallDevice
                                                                    ? 'top'
                                                                    : 'right'
                                                            }
                                                            overlay="Delete Hinge hole">
                                                            <Button
                                                                variant="link"
                                                                onClick={() => {
                                                                    deleteHingeHole(
                                                                        itemIndex
                                                                    );
                                                                }}>
                                                                <Icon iconName="Options-Delete.svg" />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </section>
                                        </Col>
                                        <Col>
                                            <GenericFields
                                                fields={hingeHole.fields}
                                                fieldsetName={hingeHole.name}
                                                indexedValues={indexedValue}
                                                fieldsetLayout={
                                                    FIELDSET_LAYOUT.COMPOUND
                                                }
                                                selectHandler={(
                                                    name,
                                                    value
                                                ) => {
                                                    const drillings =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                values[
                                                                    fieldName
                                                                ]
                                                            )
                                                        );

                                                    drillings[itemIndex] = {
                                                        drilling_offset_y:
                                                            value,
                                                    };

                                                    if (selectHandler)
                                                        selectHandler(
                                                            fieldName,
                                                            drillings
                                                        );
                                                    else
                                                        setFieldValue(
                                                            fieldName,
                                                            drillings
                                                        );
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <hr style={{opacity: isQFP ? 0.1 : 1}} />
                                </React.Fragment>
                            );
                        })}
                </Col>
            </Row>
            <Row>
                <Col
                    md={{
                        offset: isQFP ? 0 : 6,
                        span: isQFP ? (mediumToLargeDevice ? 6 : 'auto') : 3,
                    }}
                    style={isQFP ? {paddingRight: 5} : undefined}
                    className="buttons-container-shelf">
                    <CBCButton
                        tabIndex="-1"
                        className="button-blue"
                        disabled={!buttonEnabled}
                        onClick={createEvenSpace}
                        iconName="Button-Auto-Positions.svg">
                        Space Evenly
                    </CBCButton>
                </Col>
                <Col
                    md={{span: isQFP ? (mediumToLargeDevice ? 6 : 'auto') : 3}}
                    style={isQFP ? {padding: 0, paddingLeft: 5} : undefined}
                    className="buttons-container-shelf">
                    <CBCButton
                        tabIndex="-1"
                        disabled={!buttonEnabled}
                        onClick={() => {
                            addHingeHole(1);
                        }}
                        iconName="Button-Add.svg">
                        Add Hinge hole
                    </CBCButton>
                </Col>
            </Row>
            {isQFP ? <div style={{height: '15px'}} /> : <></>}
        </>
    );
};
