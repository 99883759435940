import {
    Product,
    GenericObject,
    Structure,
    Drawer,
} from 'components/customer/Product/entity';
import {MATERIAL_NAMES_MAP} from 'components/customer/Materials/helper/constants';
import {validSubpanels} from 'shared/helpers';
import excel from 'shared/Excel';
import {getFieldNames} from 'components/customer/Product/Drawer/helpers/getDrawers';
import {getFaceHeight} from 'components/customer/Product/Drawer/helpers';
import {cloneDeep} from 'lodash';

export const PRODUCT_TYPES = {
    COMPONENTS: 5,
};

interface MapperInterface {
    initialValues: GenericObject;
    productData: Partial<Product>;
    shelvesStructureStore: {
        current: GenericObject;
    };
    simpleShelvesReference: {
        current: GenericObject;
    };
    partitionHeightReferenceStore: {
        current: string;
    };
    cabinetId: number;
    shelvesOptions?: {
        maximum: number;
    };
    drawerRunnerInfoReference: GenericObject;
    productConfig: Structure[];
}

export const mapInitialValues = ({
    initialValues,
    productData,
    shelvesStructureStore,
    simpleShelvesReference,
    partitionHeightReferenceStore,
    cabinetId,
    shelvesOptions,
    drawerRunnerInfoReference,
    productConfig,
}: MapperInterface) => {
    const excludeAfterDoors: string[] = [];

    if (Object.keys(productData).length) {
        // sets initial Values to data stored in database or product default
        Object.keys(productData).forEach((fieldName) => {
            if (fieldName === 'doors') {
                const doors = productData.doors;
                initialValues.vert_mid_rail_positions = [];
                initialValues.hori_mid_rail_positions = [];
                initialValues.glassSubPanels = validSubpanels([
                    [false],
                ]) as boolean[][];
                let subPanelIndex = 0;
                let panelIndex = 0;

                if (doors.length) {
                    const door = doors[0];
                    Object.keys(door).forEach((doorFieldName) => {
                        initialValues.hori_amount = door.hori_amount;
                        initialValues.vert_amount = door.vert_amount;
                        initialValues.hori_mid_rail_positions =
                            door.horizontal_positions;
                        initialValues.vert_mid_rail_positions =
                            door.vertical_positions;

                        if (initialValues.hasOwnProperty(doorFieldName)) {
                            initialValues[doorFieldName] = door[doorFieldName];

                            excludeAfterDoors.push(doorFieldName); // do not use field outside door array
                        }

                        if (doorFieldName.indexOf('sub_panel') >= 0) {
                            const subPanel =
                                initialValues.glassSubPanels[
                                    Number(subPanelIndex)
                                ];

                            subPanel[Number(panelIndex)] =
                                door[doorFieldName] == 1;
                            panelIndex++;
                            if (panelIndex === 3) {
                                subPanelIndex++;
                                panelIndex = 0;
                            }
                        }
                    });
                }
            } else if (
                fieldName === 'drawers' &&
                initialValues.hasOwnProperty('drawers')
            ) {
                const drawers = productData.drawers;

                if (drawers.length >= initialValues.drawers.length) {
                    initialValues.drawers = drawers.map((drawer) => {
                        const runner: Drawer = {
                            id: drawer['id'],
                            drawer_face_height: drawer['drawer_face_height'],
                            drawer_style: drawer['drawer_style'],
                            drawer_type: drawer['insert_id'],
                        };

                        if (drawer.id != -1) {
                            runner.drawer_runner_specs = `${drawer['drawer_runner_height']}x${drawer['drawer_runner_depth']} : ${drawer.drawer_runner_desc}`;
                        }

                        return runner;
                    });
                } else {
                    initialValues.drawers = initialValues.drawers.map(
                        (drawer, index) => {
                            return {
                                ...drawer,
                                ...drawers[Number(index)],
                            };
                        }
                    );
                }
            } else if (
                fieldName === 'drawers' &&
                !initialValues.hasOwnProperty('drawers')
            ) {
                const drawers = productData.drawers;

                if (productData.cabinet.attributes.style === 5) {
                    // checking if this is component
                    drawers.forEach((drawer) => {
                        initialValues.drawer_runner_specs = `${drawer['drawer_runner_height']}x${drawer['drawer_runner_depth']} : ${drawer.drawer_runner_desc}`;
                        initialValues.drawer_type = drawer['insert_id'];
                    });
                }
            } else if (
                fieldName === 'shelves' &&
                productData.hasOwnProperty('shelves')
            ) {
                if (
                    Array.isArray(productData['shelves']) &&
                    productData['shelves'].length
                ) {
                    initialValues.simple_shelves_amount =
                        productData['shelves'].length;
                    initialValues.simple_shelf_type = productData[
                        'shelves'
                    ].every((shelf) => shelf.shelf_style == 1)
                        ? 1
                        : 0;
                    initialValues.shelf_set_back = productData.shelf_set_back;
                } else {
                    if (initialValues.hasOwnProperty('simple_shelves_amount')) {
                        if (
                            typeof cabinetId === 'undefined' &&
                            productData.hasOwnProperty('cabinet') &&
                            productData.cabinet.hasOwnProperty('attributes') &&
                            productData.cabinet.attributes.hasOwnProperty(
                                'default_shelves'
                            ) &&
                            (initialValues.simple_shelves_amount === 0 ||
                                initialValues.simple_shelves_amount === null)
                        ) {
                            if (
                                productData.cabinet.attributes.default_shelves >
                                    10 &&
                                productData.hasOwnProperty('cabinet_height')
                            ) {
                                initialValues.simple_shelves_amount =
                                    Math.round(
                                        parseFloat(
                                            productData.cabinet_height as string
                                        ) /
                                            parseFloat(
                                                `${productData.cabinet.attributes.default_shelves}`
                                            )
                                    );
                                if (
                                    shelvesOptions &&
                                    shelvesOptions.maximum &&
                                    shelvesOptions.maximum <
                                        initialValues.simple_shelves_amount
                                ) {
                                    initialValues.simple_shelves_amount =
                                        shelvesOptions.maximum;
                                }
                            } else {
                                if (
                                    shelvesOptions &&
                                    shelvesOptions.maximum &&
                                    shelvesOptions.maximum <
                                        productData.cabinet.attributes
                                            .default_shelves
                                ) {
                                    initialValues.simple_shelves_amount =
                                        shelvesOptions.maximum;
                                } else {
                                    initialValues.simple_shelves_amount =
                                        productData.cabinet.attributes.default_shelves;
                                }
                            }
                        } else {
                            initialValues.simple_shelves_amount =
                                productData.shelves.length;
                        }
                    }

                    if (
                        initialValues.hasOwnProperty('simple_shelf_type') &&
                        productData.hasOwnProperty('cabinet') &&
                        productData.cabinet.hasOwnProperty('attributes') &&
                        productData.cabinet.attributes.hasOwnProperty(
                            'shelf_type'
                        ) &&
                        (initialValues.simple_shelf_type === 0 ||
                            initialValues.simple_shelf_type === null)
                    ) {
                        initialValues.simple_shelf_type =
                            productData.cabinet.attributes.shelf_type;
                    }
                    if (productData.hasOwnProperty('shelf_set_back')) {
                        initialValues.shelf_set_back =
                            productData.shelf_set_back;
                    }
                }
            } else if (fieldName === 'drillings') {
                if (
                    productData.hasOwnProperty('drillings') &&
                    Array.isArray(productData.drillings)
                ) {
                    initialValues.drillings = productData['drillings'].map(
                        (drilling) => ({
                            drilling_offset_x: drilling['offset_x'],
                            drilling_offset_y: drilling['offset_y'],
                            drilling_num_holes: drilling['num_holes'],
                            drilling_pitch: drilling['pitch'],
                        })
                    );
                }
            } else if (
                fieldName === 'drill_only_hinge_details' &&
                productData.drill_only_hinge_details.drill_only_hinge &&
                productData.hasOwnProperty('hinge_style') &&
                productData.hinge_style != -1
            ) {
                initialValues['drill_only_hinge'] =
                    productData.drill_only_hinge_details.drill_only_hinge;
                initialValues['drill_only_hinge_name'] =
                    productData.drill_only_hinge_details.name;
            } else if (
                fieldName === 'drawer_panel_edges' &&
                Array.isArray(productData?.drawer_panel_edges) &&
                productData?.drawer_panel_edges?.length > 0 &&
                productData?.drawer_amount > 1
            ) {
                initialValues.drawer_panel_edges =
                    productData.drawer_panel_edges;
            } else if (fieldName == 'simple_shelves') {
                initialValues.cabinet_simple_shelves = productData[
                    'simple_shelves'
                ]
                    ? true
                    : false;
            } else {
                if (
                    initialValues.hasOwnProperty(fieldName) &&
                    !excludeAfterDoors.includes(fieldName)
                ) {
                    initialValues[fieldName] = productData[fieldName];
                }
            }
        });

        // Map material values, since field names while saving and while retrieving data are different
        Object.keys(MATERIAL_NAMES_MAP).forEach((fieldName) => {
            if (initialValues.hasOwnProperty(fieldName)) {
                if (!productData.hasOwnProperty('door_suffix_id')) {
                    productData.door_suffix_id = -1;
                }

                initialValues[String(fieldName)] = excel.calculate(
                    MATERIAL_NAMES_MAP[String(fieldName)],
                    productData
                );
            }
        });

        // Shelves data
        Object.keys(shelvesStructureStore.current).forEach((shelfFieldset) => {
            const shelfFields = shelvesStructureStore.current[shelfFieldset];
            const typeField = shelfFields.find(
                (field) => field.indexOf('type') >= 0
            );
            const styleField = shelfFields.find(
                (field) => field.indexOf('shelf_style') >= 0
            );
            const positionField = shelfFields.find(
                (field) => field.indexOf('shelf_position') >= 0
            );
            const setbackField = shelfFields.find(
                (field) => field.indexOf('shelf_offset') >= 0
            );

            if (
                typeField &&
                productData.hasOwnProperty(shelfFieldset) &&
                Array.isArray(productData[shelfFieldset])
            ) {
                initialValues[shelfFieldset] = productData[shelfFieldset].map(
                    (shelf) => {
                        if (shelf.hasOwnProperty('insert_id')) {
                            shelf[typeField] = shelf.insert_id;
                            shelf[styleField] = shelf.shelf_style;
                            shelf[positionField] = shelf.shelf_position;
                            shelf[setbackField] = shelf.shelf_offset;
                        }
                        return shelf;
                    }
                );
            }
        });

        if (
            initialValues.hasOwnProperty('cabinet_width') &&
            productData.hasOwnProperty('cabinet') &&
            productData.cabinet.hasOwnProperty('attributes') &&
            productData.cabinet.attributes.hasOwnProperty('default_width') &&
            initialValues.cabinet_width === 0
        ) {
            initialValues.cabinet_width =
                productData.cabinet.attributes.default_width;
        }
    }

    if (
        initialValues.hasOwnProperty('cabinet_left_depth') &&
        productData.hasOwnProperty('cabinet_width_depth')
    ) {
        initialValues.cabinet_left_depth = productData.cabinet_width_depth;
    }

    if (
        initialValues.hasOwnProperty('cabinet_right_depth') &&
        productData.hasOwnProperty('cabinet_length_depth')
    ) {
        initialValues.cabinet_right_depth = productData.cabinet_length_depth;
    }

    if (
        initialValues.hasOwnProperty('oven_opening_height') &&
        productData.hasOwnProperty('oven_height')
    ) {
        initialValues.oven_opening_height = productData.oven_height;
    }

    if (
        initialValues.hasOwnProperty('cabinet_partition_height') &&
        initialValues.cabinet_partition_height === 0
    ) {
        if (productData.hasOwnProperty('partition_height')) {
            initialValues.cabinet_partition_height =
                productData.partition_height;
        } else if (
            productData.hasOwnProperty('cabinet') &&
            productData.cabinet.hasOwnProperty('attributes') &&
            productData.cabinet.attributes.hasOwnProperty('partition')
        ) {
            initialValues.cabinet_partition_height =
                productData.cabinet.attributes.partition;
        }
    }

    if (
        initialValues.hasOwnProperty('oven_opening_height') &&
        initialValues.oven_opening_height === -1
    )
        initialValues.oven_opening_height = 0;

    if (
        initialValues.hasOwnProperty('oven_advanced_checkbox') &&
        initialValues.oven_advanced_checkbox === -1
    ) {
        initialValues.oven_advanced_checkbox = false;

        if (productData.hasOwnProperty('oven_check')) {
            initialValues.oven_advanced_checkbox = productData.oven_check;
        }
    }

    if (
        initialValues.hasOwnProperty('rangehood_opening_height') &&
        initialValues.rangehood_opening_height === -1
    )
        initialValues.rangehood_opening_height = 0;

    if (
        initialValues.hasOwnProperty('rangehood_advanced_checkbox') &&
        initialValues.rangehood_advanced_checkbox === -1
    )
        initialValues.rangehood_advanced_checkbox = false;

    if (
        initialValues.hasOwnProperty('microwave_opening_height') &&
        initialValues.microwave_opening_height === -1
    )
        initialValues.microwave_opening_height = false;

    if (
        initialValues.hasOwnProperty('microwave_advanced_checkbox') &&
        initialValues.microwave_advanced_checkbox === -1
    )
        initialValues.microwave_advanced_checkbox = 0;

    // set fingerPUll value from db
    if (productData.hasOwnProperty('finger_pull') && productData.finger_pull)
        initialValues.fingerPull_styles = productData.finger_pull;

    if (
        initialValues.hasOwnProperty('cabinet_hori_shelves') &&
        productData.hasOwnProperty('hori_shelf_amount')
    ) {
        initialValues.cabinet_hori_shelves = productData.hori_shelf_amount;
    }

    if (
        initialValues.hasOwnProperty('cabinet_vert_shelves') &&
        productData.hasOwnProperty('vert_shelf_amount')
    ) {
        initialValues.cabinet_vert_shelves = productData.vert_shelf_amount;
    }

    if (
        initialValues.hasOwnProperty('cabinet_width_door_1') &&
        productData.hasOwnProperty('cabinet_width')
    ) {
        initialValues.cabinet_width_door_1 = productData.cabinet_width;
    }

    if (
        initialValues.hasOwnProperty('cabinet_width_door_2') &&
        productData.hasOwnProperty('cabinet_width1')
    ) {
        initialValues.cabinet_width_door_2 = productData.cabinet_width1;
    }

    if (initialValues.hasOwnProperty('cabinet_edge_L1')) {
        if (productData.hasOwnProperty('cabinet_edge_l1')) {
            initialValues.cabinet_edge_L1 = productData.cabinet_edge_l1;
        } else {
            initialValues.cabinet_edge_L1 = 1;
            // unedged for component panels
            if (
                (productData.cabinet.attributes.style =
                    PRODUCT_TYPES.COMPONENTS &&
                    productData.cabinet.attributes.product_type == 4)
            ) {
                initialValues.cabinet_edge_L1 = 0;
            }
        }
    }

    if (initialValues.hasOwnProperty('cabinet_edge_L2')) {
        if (productData.hasOwnProperty('cabinet_edge_l2')) {
            initialValues.cabinet_edge_L2 = productData.cabinet_edge_l2;
        } else {
            initialValues.cabinet_edge_L2 = 1;
            // unedged for component panels
            if (
                (productData.cabinet.attributes.style =
                    PRODUCT_TYPES.COMPONENTS &&
                    productData.cabinet.attributes.product_type == 4)
            ) {
                initialValues.cabinet_edge_L2 = 0;
            }
        }
    }

    if (initialValues.hasOwnProperty('cabinet_edge_W1')) {
        if (productData.hasOwnProperty('cabinet_edge_w1')) {
            initialValues.cabinet_edge_W1 = productData.cabinet_edge_w1;
        } else {
            initialValues.cabinet_edge_W1 = 1;
            // unedged for component panels
            if (
                (productData.cabinet.attributes.style =
                    PRODUCT_TYPES.COMPONENTS &&
                    productData.cabinet.attributes.product_type == 4)
            ) {
                initialValues.cabinet_edge_W1 = 0;
            }
        }
    }

    if (initialValues.hasOwnProperty('cabinet_edge_W2')) {
        if (productData.hasOwnProperty('cabinet_edge_w2')) {
            initialValues.cabinet_edge_W2 = productData.cabinet_edge_w2;
        } else {
            initialValues.cabinet_edge_W2 = 1;
            // unedged for component panels
            if (
                (productData.cabinet.attributes.style =
                    PRODUCT_TYPES.COMPONENTS &&
                    productData.cabinet.attributes.product_type == 4)
            ) {
                initialValues.cabinet_edge_W2 = 0;
            }
        }
    }

    if (
        initialValues.hasOwnProperty('cabinet_edge_inner') &&
        !productData.hasOwnProperty('cabinet_edge_inner')
    ) {
        initialValues.cabinet_edge_inner = 1;
    }

    if (
        initialValues.hasOwnProperty('panel_edge_top') &&
        !productData.hasOwnProperty('panel_edge_top')
    ) {
        initialValues.panel_edge_top = 1;
    }

    if (
        initialValues.hasOwnProperty('panel_edge_bottom') &&
        !productData.hasOwnProperty('panel_edge_bottom')
    ) {
        initialValues.panel_edge_bottom = 1;
    }

    if (
        initialValues.hasOwnProperty('panel_edge_left') &&
        !productData.hasOwnProperty('panel_edge_left')
    ) {
        initialValues.panel_edge_left = 1;
    }

    if (
        initialValues.hasOwnProperty('panel_edge_right') &&
        !productData.hasOwnProperty('panel_edge_right')
    ) {
        initialValues.panel_edge_right = 1;
    }

    if (
        initialValues.hasOwnProperty('cabinet_upper_partition_height') &&
        productData.hasOwnProperty('upper_partition_height') &&
        partitionHeightReferenceStore.current &&
        parseFloat(`${productData.upper_partition_height}`) > 1
    ) {
        if (
            typeof productData.upper_partition_height === 'string' &&
            productData.upper_partition_height.indexOf('%') >= 0
        ) {
            initialValues.cabinet_upper_partition_height = (
                parseFloat(productData.upper_partition_height) / 100
            ).toFixed(2);
        } else
            initialValues.cabinet_upper_partition_height = parseFloat(
                (
                    productData.upper_partition_height /
                    excel.calculate(
                        partitionHeightReferenceStore.current,
                        initialValues
                    )
                ).toFixed(2)
            );
    }

    if (
        initialValues.hasOwnProperty('cabinet_lower_partition_height') &&
        productData.hasOwnProperty('lower_partition_height') &&
        partitionHeightReferenceStore.current &&
        parseFloat(`${productData.lower_partition_height}`) > 1
    ) {
        if (
            typeof productData.lower_partition_height === 'string' &&
            productData.lower_partition_height.indexOf('%') >= 0
        ) {
            initialValues.cabinet_lower_partition_height = (
                parseFloat(productData.lower_partition_height) / 100
            ).toFixed(2);
        } else
            initialValues.cabinet_lower_partition_height = parseFloat(
                (
                    productData.lower_partition_height /
                    excel.calculate(
                        partitionHeightReferenceStore.current,
                        initialValues
                    )
                ).toFixed(2)
            );
    }

    if (
        !initialValues.hasOwnProperty('drawers') &&
        initialValues.hasOwnProperty('drawer_face_height') &&
        initialValues.drawer_face_height.filter(Boolean).length == 0 &&
        initialValues.drawer_amount > 0
    ) {
        const faceHeight = initialValues.hasOwnProperty('cabinet_height')
            ? initialValues.cabinet_height
            : initialValues.cabinet_panel_length;

        if (faceHeight) {
            initialValues.drawer_face_height = new Array(
                initialValues.drawer_amount
            ).fill(Math.floor(faceHeight / initialValues.drawer_amount));
        }
    }

    simpleShelvesReference.current = initialValues.simple_shelves_amount;

    if (
        initialValues.hasOwnProperty('cabinet_partition_width') &&
        productData.hasOwnProperty('partition_width')
    ) {
        initialValues.cabinet_partition_width =
            productData.partition_width <= 0
                ? initialValues.cabinet_width / 2
                : productData.partition_width;
    }

    if (initialValues.hasOwnProperty('hor_grain_ext')) {
        initialValues.hor_grain_ext = initialValues.hor_grain_ext == 1;
    }

    if (initialValues.hasOwnProperty('hor_grain_carc')) {
        initialValues.hor_grain_carc = initialValues.hor_grain_carc == 1;
    }

    if (initialValues.hasOwnProperty('double_sided_ext')) {
        initialValues.double_sided_ext = initialValues.double_sided_ext == 1;
    }

    if (initialValues.hasOwnProperty('double_sided_carc')) {
        initialValues.double_sided_carc = initialValues.double_sided_carc == 1;
    }

    if (initialValues.custom_colour_ext == -1) {
        initialValues.custom_colour_ext = '';
    }

    if (initialValues.custom_colour_carc == -1) {
        initialValues.custom_colour_carc = '';
    }

    if (initialValues.hasOwnProperty('drawers') && drawerRunnerInfoReference) {
        const totalDrawerHeight = excel.calculate(
            drawerRunnerInfoReference.totalDrawerHeight,
            initialValues
        );
        const {faceHeightFieldName, drawerStyleFieldName} = getFieldNames(
            productConfig.find((structure) => structure.name == 'Drawers')
        );

        const drawers = cloneDeep(initialValues.drawers);
        for (let i = 0; i < drawers.length; i++) {
            if (drawers[i].id == -1) {
                const faceHeight = getFaceHeight(
                    drawers,
                    i,
                    totalDrawerHeight,
                    initialValues.cabinet_drawer_bottom,
                    initialValues.cabinet_drawer_top,
                    initialValues.cabinet_drawer_gap,
                    faceHeightFieldName,
                    drawerStyleFieldName
                );

                drawers[i].drawer_face_height = faceHeight;
            }
        }

        initialValues.drawers = drawers;
    }

    return initialValues;
};
