import {useFormikContext} from 'formik';
import {useEffect, useRef} from 'react';
import {DrawerFields} from 'components/customer/Product/entity/Drawer';
import {cloneDeep, isEqual} from 'lodash';
import {useProductContext} from 'contexts';
import excel from 'shared/Excel';
import {
    getFaceHeight,
    readjustLastAutoHeight,
    toNumber,
} from 'components/customer/Product/Drawer/helpers';

interface RecalculateFaceHeightInterface {
    isDrawerComponent?: boolean;
    drawerGapFieldName?: string;
    totalDrawerHeight?: string;
    drawerTopMarginFieldName?: string;
    drawerBottomMarginFieldName?: string;
}

interface FaceHeightPropsInterface {
    totalDrawerHeight?: number;
    drawerGap?: number;
    drawerTopMargin?: number;
    drawerBottomMargin?: number;
    drawerStyles?: number[];
    drawerFaceHeights?: number[];
}

export const useRecalculateFaceHeight = ({
    isDrawerComponent = false,
    totalDrawerHeight,
    drawerGapFieldName,
    drawerTopMarginFieldName,
    drawerBottomMarginFieldName,
}: RecalculateFaceHeightInterface) => {
    const {values, setFieldValue, touched} = useFormikContext<DrawerFields>();
    const {getMaterialOptions} = useProductContext<{
        getMaterialOptions: () => object;
    }>();

    const faceHeightProps = useRef<FaceHeightPropsInterface>({});
    const getFaceHeightProps = (): FaceHeightPropsInterface => {
        const materialOptions = getMaterialOptions();
        const totalHeight = excel.calculate<number>(totalDrawerHeight, {
            ...values,
            ...materialOptions,
        });

        const drawerGap = values[String(drawerGapFieldName)];
        const drawerTopMargin = values[String(drawerTopMarginFieldName)];
        const drawerBottomMargin = values[String(drawerBottomMarginFieldName)];

        return {
            totalDrawerHeight: totalHeight,
            drawerGap: toNumber(drawerGap),
            drawerTopMargin: toNumber(drawerTopMargin),
            drawerBottomMargin: toNumber(drawerBottomMargin),
            drawerStyles: values.drawers.map((drawer) => drawer.drawer_style),
            drawerFaceHeights: values.drawers.map(
                (drawer) => drawer.drawer_face_height
            ),
        };
    };

    useEffect(() => {
        if (
            !isDrawerComponent &&
            (touched.drawers ||
                touched[String(drawerGapFieldName)] ||
                touched[String(drawerTopMarginFieldName)] ||
                touched[String(drawerBottomMarginFieldName)] ||
                touched['cabinet_height'] ||
                touched['appliance_id'] ||
                touched['microwave_id'] ||
                touched['oven_opening_height'] ||
                touched['cabinet_depth'] ||
                touched['microwave_opening_height'] ||
                touched['cabinet_total_drawer_height'])
        ) {
            const props = getFaceHeightProps();
            if (!isEqual(props, faceHeightProps.current)) {
                faceHeightProps.current = props;

                let drawers = cloneDeep(values.drawers);
                drawers.forEach((drawer, index) => {
                    if (drawer.drawer_style == 1) {
                        const faceHeight = getFaceHeight(
                            drawers,
                            index,
                            props.totalDrawerHeight,
                            props.drawerBottomMargin,
                            props.drawerTopMargin,
                            props.drawerGap
                        );

                        drawer.drawer_face_height = faceHeight;
                    }
                });

                drawers = readjustLastAutoHeight(
                    drawers,
                    props.totalDrawerHeight,
                    props.drawerBottomMargin,
                    props.drawerTopMargin,
                    props.drawerGap
                );

                if (!isEqual(drawers, values.drawers)) {
                    setFieldValue('drawers', drawers);
                }
            }
        }
    }, [values]);
};
