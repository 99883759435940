import {
    HardwareOption,
    Product as ProductInterface,
} from 'components/customer/Product/entity';
import {PartialRoom} from 'shared/types/PartialRoom';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';

export const getSupplyDefaultSelection = ({
    product,
    room,
    hardwareOptions,
    cabinetFormFieldDefaults,
}: {
    product: ProductInterface;
    room: PartialRoom;
    hardwareOptions: HardwareOption[];
    cabinetFormFieldDefaults: Record<string, string | number>;
}): SelectionInterface[] => {
    const rules = [];

    if (typeof product.cabinet_include_assembly != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_include_assembly !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_include_assembly !=
                    product.cabinet_include_assembly &&
                product.cabinet_include_assembly !=
                    parseInt(room.includeAssembly)) ||
            (typeof cabinetFormFieldDefaults.cabinet_include_assembly ==
                'undefined' &&
                product.cabinet_include_assembly !=
                    parseInt(room.includeAssembly))
        ) {
            const productAssemblyName = hardwareOptions.find(
                (hardware) =>
                    hardware.assembly &&
                    hardware.value == product.cabinet_include_assembly
            ).name;
            const roomAssemblyName = hardwareOptions.find(
                (hardware) =>
                    hardware.assembly &&
                    hardware.value == parseInt(room.includeAssembly)
            ).name;
            rules.push({
                key: 'include_assembly',
                name: 'Include Assembly',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isIncludeAssemblySet,
                set_key: 'isIncludeAssemblySet',
                room_data: roomAssemblyName,
                product_data: productAssemblyName,
                value: product.cabinet_include_assembly,
                image: product.cabinet_include_assembly
                    ? '/templates/Assembled.svg'
                    : '/templates/FlatPack.svg',
                apply_to_room: true,
            });
        }
    }

    if (typeof product.cabinet_include_hardware != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_include_hardware !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_include_hardware !=
                    product.cabinet_include_hardware &&
                product.cabinet_include_hardware ==
                    parseInt(room.excludeHardware)) ||
            (typeof cabinetFormFieldDefaults.cabinet_include_hardware ==
                'undefined' &&
                product.cabinet_include_hardware ==
                    parseInt(room.excludeHardware))
        ) {
            const productHardwareName = hardwareOptions.find(
                (hardware) =>
                    hardware.supplyHardware &&
                    hardware.value == product.cabinet_include_hardware
            ).name;
            const roomHardware = room.excludeHardware == '1' ? 0 : 1;
            const roomHardwareName = hardwareOptions.find(
                (hardware) =>
                    hardware.supplyHardware && hardware.value == roomHardware
            ).name;
            rules.push({
                key: 'exclude_hardware',
                name: 'Include Hardware',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isExcludeHardwareSet,
                set_key: 'isExcludeHardwareSet',
                room_data: roomHardwareName,
                product_data: productHardwareName,
                image: product.cabinet_include_hardware
                    ? '/templates/Hardware.svg'
                    : '/templates/DrilledPanel.svg',
                value: !product.cabinet_include_hardware,
                apply_to_room: true,
            });
        }
    }

    if (
        typeof product.cabinet_adjustable_legs != 'undefined' &&
        product.cabinet_adjustable_legs != parseInt(room.adjustableLegs)
    ) {
        const productAdjustableLegsName = hardwareOptions.find(
            (hardware) =>
                hardware.adjLegs &&
                hardware.value == product.cabinet_adjustable_legs
        ).name;
        const roomAdjustableLegsName = hardwareOptions.find(
            (hardware) =>
                hardware.adjLegs &&
                hardware.value == parseInt(room.adjustableLegs)
        ).name;
        rules.push({
            key: 'adjustable_legs',
            name: 'Adjustable Legs',
            is_saved:
                room.customerOptions &&
                room.customerOptions.isAdjustableLegsSet,
            set_key: 'isAdjustableLegsSet',
            room_data: roomAdjustableLegsName,
            product_data: productAdjustableLegsName,
            image: product.cabinet_adjustable_legs
                ? '/templates/Legs.svg'
                : '/templates/NoLegs.svg',
            value: product.cabinet_adjustable_legs,
            apply_to_room: true,
        });
    }

    return rules;
};
