import {getSectionFieldsMap} from 'hooks/Product/FormFieldStructure';
import {GenericError, Structure} from 'components/customer/Product/entity';

interface TabErrorInterface {
    [key: string]: number;
}

export const getTabErrors = (
    formStructure: Structure[],
    errors: GenericError[]
) => {
    const tabErrorValues: TabErrorInterface = {};
    const filteredFormStructure = formStructure.filter(
        (fs) => fs?.layout != 'sidebar'
    );
    const fields = getSectionFieldsMap(filteredFormStructure);

    const materialTabIndex =
        filteredFormStructure
            .map((fs) => fs.name.toLowerCase())
            .indexOf('materials') || 1;

    if (errors?.find((e) => e.fields && e.fields.includes('custom_colour_ext')))
        fields['custom_colour_ext'] = materialTabIndex;

    if (
        errors?.find((e) => e.fields && e.fields.includes('custom_colour_carc'))
    )
        fields['custom_colour_carc'] = materialTabIndex;

    Object.keys(fields).forEach((fieldName) => {
        errors.forEach((error) => {
            if (
                error.fields &&
                Array.isArray(error.fields) &&
                error.fields
                    .map((field) => field.indexOf(fieldName) > -1)
                    .some(Boolean)
            ) {
                // checking for presence of fieldName as indexed fieldname won't match
                // and we don't have index here to check
                if (tabErrorValues[String(fields[String(fieldName)])]) {
                    tabErrorValues[String(fields[String(fieldName)])] += 1;
                } else {
                    tabErrorValues[String(fields[String(fieldName)])] = 1;
                }
            }
        });
    });

    return tabErrorValues;
};
