import {GenericFields} from 'components/customer/Product';
import {useProductContext} from 'contexts';
import {useFormikContext} from 'formik';
import {POSITION_TYPES, useShelvesInit} from 'hooks';
import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import excel from 'shared/Excel';
import {CBCButton} from 'shared/helpers';
import {isDeviceLarge} from 'shared/helpers/DeviceSize';

export const PartitionHeights = ({fields, fieldset}) => {
    const {setPositionType, setHeightReference, shelvesPositionType} =
        useProductContext();
    const {values, setFieldValue} = useFormikContext();
    const isLargeDevice = isDeviceLarge();

    useShelvesInit(fieldset.name);

    const autoPosition = (override = false) => {
        if (
            fieldset.hasOwnProperty('options') &&
            fieldset.options.hasOwnProperty('field1') &&
            fieldset.options.hasOwnProperty('field2')
        ) {
            const field1 = fieldset.options.field1;
            const field2 = fieldset.options.field2;

            if (
                !values.hasOwnProperty(field1) ||
                (values.hasOwnProperty(field1) &&
                    (values[field1] === 0 ||
                        values[field1] === null ||
                        override))
            ) {
                setFieldValue(field1, 0.66);
            }

            if (
                !values.hasOwnProperty(field2) ||
                (values.hasOwnProperty(field2) &&
                    (values[field2] === 0 ||
                        values[field2] === null ||
                        override))
            ) {
                setFieldValue(field2, 0.33);
            }
        }
    };

    const heightField =
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('totalHeight')
            ? fieldset.options.totalHeight
            : false;
    let effectModifiers = [];

    if (typeof heightField === 'string') {
        if (values.hasOwnProperty(heightField)) {
            effectModifiers.push(values[heightField]);
        } else {
            try {
                let height = excel.calculate(effectModifiers, values);

                effectModifiers.push(height);
            } catch (e) {
                effectModifiers.push(values.cabinet_height); // use cabinet height if there is no reference values provided
            }
        }
    }

    useEffect(() => {
        autoPosition();

        setHeightReference(fieldset.name, parseFloat(effectModifiers[0]));
    }, effectModifiers);

    return (
        <>
            <Row>
                <Col>
                    <GenericFields
                        fields={fieldset.fields}
                        fieldsetName={fieldset.name}
                        fieldsetTitle={fieldset.title}
                        formLabelSpanOverride={6}
                    />
                </Col>
            </Row>
            <Row className="buttons-container-shelf-row">
                <Col
                    md={isLargeDevice ? {offset: 4, span: 4} : undefined}
                    className="buttons-container-shelf">
                    <CBCButton
                        tabIndex="-1"
                        iconName="Button-Auto-Positions.svg"
                        onClick={() => {
                            autoPosition(true);
                        }}>
                        Auto Positions
                    </CBCButton>
                </Col>

                {shelvesPositionType.hasOwnProperty(fieldset.name) &&
                    shelvesPositionType[fieldset.name] ==
                        POSITION_TYPES.DECIMAL && (
                        <Col
                            md={isLargeDevice ? 4 : undefined}
                            className="buttons-container-shelf">
                            <CBCButton
                                tabIndex="-1"
                                iconName="Button-Percentage.svg"
                                onClick={() =>
                                    setPositionType(
                                        fieldset.name,
                                        POSITION_TYPES.PERCENTAGE
                                    )
                                }>
                                Convert to %
                            </CBCButton>
                        </Col>
                    )}
                {(!shelvesPositionType.hasOwnProperty(fieldset.name) ||
                    shelvesPositionType[fieldset.name] ==
                        POSITION_TYPES.PERCENTAGE) && (
                    <Col
                        md={isLargeDevice ? 4 : undefined}
                        className="buttons-container-shelf">
                        <CBCButton
                            tabIndex="-1"
                            style={{textTransform: 'none'}}
                            iconName="Button-Convert-mm.svg"
                            onClick={() =>
                                setPositionType(
                                    fieldset.name,
                                    POSITION_TYPES.DECIMAL
                                )
                            }>
                            Convert To mm
                        </CBCButton>
                    </Col>
                )}

                <Col />
            </Row>
        </>
    );
};
