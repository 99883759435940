import React, { useEffect, useState } from "react";
import { useConfirmationDialog } from "shared";
import { useAppContext } from "contexts";
import { useNavigate, useLocation } from "react-router-dom";

export const InvalidProduct = ({message}) => {
    const [invalidMessage, setInvalidMessage] = useState(message);
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    let location = useLocation();
    const navigate = useNavigate();
    const {userProfile}  = useAppContext();
    useEffect(() => {
        let showMessage = `
        <p>Contact the admin or manufacturer to make this product available.<p>
        <strong><p style="color:red">ERROR: ${invalidMessage}</p></strong>
        <p>Manufacturer Contact</p>
        ${userProfile.manufacturerContactInfo}`;
        showDialog({
            title: "Invalid Product",
            message : showMessage,
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: "Ok",
                    show: true,
                    action: () => {
                        navigate(location.pathname);
                        hideDialog();
                    }
                }
            ]
        });
    }, [invalidMessage]);

    return (
        <>
            {dialog}
        </>
    );
}