// @flow
import {useDrawers} from 'hooks';
import React from 'react';

type DrawerPreviewProps = {
    isAdvanced?: boolean,
};

export const DrawerPreview = ({
    isAdvanced = false,
}: DrawerPreviewProps): React$Element<any> => {
    const {position, canvasContainerId} = useDrawers({isAdvanced});

    return (
        <div
            className="panelContainer"
            style={position}
            id={canvasContainerId}
        />
    );
};
