import React from 'react';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

const CollapsibleHeaderComponent = styled.h3<{$collapsible: boolean}>`
    cursor: ${({$collapsible}) => ($collapsible ? 'pointer' : 'initial')};
    user-select: none;
    border-radius: 19px !important;
    position: relative;
    max-width: 250px;
    .add-button-container img {
        height: 22px;
        position: absolute;
        left: 4px;
        top: 4px;
    }
`;

const CancelButtonContainer = styled.div<{
    $isMobile?: boolean;
}>`
    top: ${({$isMobile}) => ($isMobile ? '40px' : '4px')};
    right: 82px;
    z-index: 3;
    position: absolute;
    img {
        position: absolute;
        height: 25px;
        left: 75px;
        top: -5px;
        border-radius: 25px;
        cursor: pointer;
        border: 2px solid ${({theme}) => theme.colors.primary.main};
        &:hover {
            border: 2px solid ${({theme}) => theme.colors.secondary.main};
        }
    }
`;

interface CollapsibleHeaderProps {
    title: string;
    collapsible?: boolean;
    onClick: () => void;
    isOpen: boolean;
}

interface CancelButtonProps {
    toggleIsOpen: () => void;
}

export const CancelButton = ({toggleIsOpen}: CancelButtonProps) => {
    const isSmallDevice = isDeviceSmall();

    return (
        <CancelButtonContainer
            $isMobile={isSmallDevice}
            className="cancel-button-container">
            <Icon iconName="Button-Cancel.svg" onClick={toggleIsOpen} />
        </CancelButtonContainer>
    );
};

const CollapsibleHeader = ({
    title,
    collapsible = false,
    onClick,
    isOpen,
}: CollapsibleHeaderProps) => {
    return (
        <>
            {!isOpen && (
                <CollapsibleHeaderComponent
                    className="notes-and-variations new"
                    onClick={() => onClick()}
                    $collapsible={collapsible}>
                    <span>
                        <div className="add-button-container">
                            <Icon iconName="Button-Add.svg" />
                        </div>
                        {title}
                    </span>
                </CollapsibleHeaderComponent>
            )}
        </>
    );
};

export default CollapsibleHeader;
