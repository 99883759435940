import {useDrillingsPreview} from 'hooks';
import React from 'react';

export const DrillingPreview = () => {
    const {position, canvasContainerId} = useDrillingsPreview();

    return (
        <div
            className="panelContainer"
            style={position}
            id={canvasContainerId}
        />
    );
};
